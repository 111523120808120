import React, { useEffect, useState } from "react";
import Button from "../../form/Button";
import { getOrdinalSuffix } from "../../../helpers";
import DeleteModal from "../../common/DeleteModal";
import { toast } from "react-toastify";
import Modal from "../../elements/Modal";
import AddNoteModal from "./AddNoteModal";
import DailyTrackItem from "./DailyTrackItem";

const DailyTraking = ({
  targetRef,
  monthIndex,
  selectedYear,
  selectedMonth,
  days = [],
  calender = {},
  saveComplete = { index: null, targetDay: null, isComplete: true },
  setdays = () => {},
  content = {},
  setContent = () => {},
}) => {
  const [itemDelete, setItemDelete] = useState({
    deleteComplete: true,
    openModal: false,
    data: {
      apiEndpoint: "task/delete-task",
      targetId: undefined,
      method: "post",
      data: null,
      payload: {
        body: {
          ids: null,
        },
      },
    },
  });
  const [addNoteModal, setAddNoteModal] = useState({
    isOpen: false,
    data: null,
  });

  const getCurrentDate = (monthIndex, day) => {
    const year = selectedYear;
    const currentMonth = monthIndex;
    const currentDate = new Date(year, currentMonth, day).getDay();
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][currentDate];
  };

  const addNote = ({ day, index }) => {
    setAddNoteModal({
      isOpen: true,
      data: { year: selectedYear, month: selectedMonth + 1, day },
    });
  };

  const daysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysCount = daysInMonth(selectedYear, monthIndex);

  useEffect(() => {
    if (daysCount) {
      setdays(
        Array.from({ length: daysCount }, (_, i) => i + 1)?.map((day, i) => ({
          _id: i?.toString(),
          day,
          notes: [],
        }))
      );
    }
  }, [daysCount]);

  const getResponseHandlerAfterDelete = (res) => {
    setItemDelete({
      deleteComplete: true,
      openModal: false,
      data: {
        apiEndpoint: "put",
        targetId: undefined,
        method: "post",
      },
    });
    if (res?.data?.status === 200) {
      setdays(
        days?.map((elm, i) =>
          elm?.day === res?.data?.data?.day
            ? {
                ...elm,
                notes: elm?.notes?.filter(
                  (_, i) => i !== res?.data?.data?.note_index
                ),
              }
            : elm
        )
      );
      toast?.success(res?.data?.message);
    } else {
      toast?.error(res?.data?.message);
    }
  };

  const setResData = async ({ data, monthlyProgress }) => {
    const updatedDaysMap = new Map();
    [data].forEach((updatedDay) => {
      delete updatedDay?.month;
      delete updatedDay?.year;
      delete updatedDay?.type;

      updatedDaysMap.set(updatedDay.day, {
        ...updatedDay,
        day: Number(updatedDay.day),
        notes: updatedDay.task?.map((task) => ({
          ...task,
          value: task.name,
        })),
      });
    });
    setdays((prevDays) => {
      updatedDaysMap?.forEach((item) => {
        delete item?.task;
        prevDays[Number(item?.day - 1)] = item;
      });
      return [...prevDays];
    });
    setContent((pre) => ({ ...pre, data: monthlyProgress }));
  };

  return (
    <>
      {days.map(({ day, notes, _id }, index) => {
        const ref_attr = {};
        if (
          calender?.currentMonth == monthIndex &&
          Number(calender?.today) == day
        ) {
          ref_attr[`ref`] = targetRef;
        }
        return (
          <div
            className="group bg-indigo-100 px-2 rounded-lg py-2 mb-3"
            key={_id}
            {...ref_attr}
          >
            <div
              className={`mb-3 flex items-center day  ${
                getCurrentDate(monthIndex, day) === "Saturday" ||
                getCurrentDate(monthIndex, day) === "Sunday"
                  ? "weekend"
                  : ""
              }`}
            >
              <div className="date text-lg  text-indigo-900 font-bold">
                {getOrdinalSuffix(day)}
              </div>
              {/* <div className="date text-lg  text-indigo-900 font-bold">
                {mon[selectedMonth]},
              </div>
              <div className="day-label text-lg text-indigo-900 font-bold tracking-wider">
                {getCurrentDate(monthIndex, day)}
              </div> */}

              <Button
                buttonLabel={""}
                buttonIconPosition={"left"}
                buttonIcon={"fa-regular fa-plus"}
                buttonLabelClasses={"capitalize"}
                tooltip={{ show: true, label: "Add" }}
                buttonClasses={"!ml-auto"}
                buttonFunction={() => addNote({ day, index })}
              />
            </div>
            <div className="relative space-y-2 mt-2 w-full">
              {notes &&
                Array.isArray(notes) &&
                notes?.length > 0 &&
                notes?.map((note, noteIndex) => (
                  <DailyTrackItem
                    key={noteIndex?.toString()}
                    remark={note?.name}
                    title={note?.subTask[0]?.name}
                    time={note?.subTask[0]?.completedTime}
                    type={note?.subTask[0]?.goalType}
                  />
                ))}
            </div>
          </div>
        );
      })}
      <DeleteModal
        setIsOpen={() =>
          setItemDelete({
            deleteComplete: true,
            openModal: false,
            data: {
              apiEndpoint: "put",
              targetId: undefined,
              method: "post",
            },
          })
        }
        isOpen={itemDelete?.openModal}
        loading={!itemDelete?.deleteComplete}
        data={itemDelete?.data}
        getResponse={getResponseHandlerAfterDelete}
        deleteFunction={() => {
          setItemDelete((pre) => ({
            ...pre,
            deleteComplete: false,
          }));
        }}
      />
      <Modal
        isOpen={addNoteModal?.isOpen}
        title={"Add Daily Tracking"}
        size="md"
        setIsOpen={() => setAddNoteModal({ isOpen: false, data: null })}
      >
        <AddNoteModal
          data={addNoteModal?.data}
          cancel={() => setAddNoteModal({ isOpen: false, data: null })}
          setResData={(res) => setResData(res)}
        />
      </Modal>
    </>
  );
};

export default DailyTraking;
