import React from "react";
import Button from "../../form/Button";

const PlanItem = () => {
  return (
    <div className="shadow-sm hover:shadow-lg rounded-xl p-5 bg-white border border-transparent hover:border-indigo-500 transition-all cursor-pointer">
      <div className="py-4 border-b border-slate-300">
        <div className="text-indigo-900">Basic</div>
        <div class="text-xs lg:text-sm xl:text-base font-medium capitalize">
          <span class="text-indigo-900 text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-bold">
            $5995
          </span>
          <span className="text-indigo-900">/year</span>
        </div>
      </div>
      <div className="pt-4">
        <ul>
          <li className="text-indigo-900 text-sm mb-2 flex gap-3">
            <span>
              <i className="fa-regular fa-check"></i>
            </span>
            <span>1 Standard Performance</span>
          </li>
          <li className="text-indigo-900 text-sm mb-2 flex gap-3">
            <span>
              <i className="fa-regular fa-check"></i>
            </span>
            <span>100 Websites</span>
          </li>
          <li className="text-indigo-900 text-sm mb-2 flex gap-3">
            <span>
              <i className="fa-regular fa-check"></i>
            </span>
            <span>100 GB SSD Storage</span>
          </li>
          <li className="text-indigo-900 text-sm mb-2 flex gap-3">
            <span>
              <i className="fa-regular fa-check"></i>
            </span>
            <span>Weekly Backups</span>
          </li>
          <li className="text-indigo-900 text-sm mb-2 flex gap-3">
            <span>
              <i className="fa-regular fa-check"></i>
            </span>
            <span>Unlimited Free SSL</span>
          </li>
        </ul>
      </div>
      <div className="mt-4">
        <Button
          buttonClasses={
            "w-full hover:bg-transparent hover:border hover:border-indigo-500 [&>span]:hover:!text-indigo-500"
          }
          buttonLabel={"Select Plan"}
        />
      </div>
    </div>
  );
};

export default PlanItem;
