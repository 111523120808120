import React, { useState } from "react";
import Input from "../form/Input";
import Button from "../form/Button";
import useForm from "../../hooks/useForm";
import { changePassword, updateProfile } from "../../service/userService";
import { toast } from "react-toastify";
import { formValidate } from "../../helpers/formValidate";

const validation = {
  oldpassword: { required: true, message: "Old password is required" },
  newpassword: { required: true, message: "New password is required" },
};

const ChangePassword = ({ close = () => {} }) => {
  const [complete, setComplete] = useState(true);
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  const onSubmit = (values) => {
    setComplete(false);
    changePassword(values).then((res) => {
      if (res?.status === 200) {
        setFieldsValue({
          oldpassword: "",
          newpassword: "",
        });
        toast.success(res?.message+" "+"Please login again");
        localStorage.removeItem("accessToken");
        window.dispatchEvent(new Event("storage"));
      } else {
        toast.error(res?.message);
      }
      setComplete(true);
    });
  };
  return (
    <>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="flex flex-col gap-y-2">
          <div className="font-semibold text-lg">Change Password</div>
          <div className="flex gap-2 mt-5">
            <div className="w-full">
              <Input
                inputName={"oldpassword"}
                inputType={"password"}
                label={"Old password"}
                inputPlaceholder={"Enter old password"}
                inputClasses={"w-full"}
                value={values?.oldpassword}
                onChange={handleChange}
                {...formValidate(errors, "oldpassword")}
              />
            </div>
            <div className="w-full">
              <Input
                inputName={"newpassword"}
                inputType={"password"}
                label={"New password"}
                inputPlaceholder={"Enter new password"}
                inputClasses={"w-full"}
                value={values?.newpassword}
                onChange={handleChange}
                {...formValidate(errors, "newpassword")}
              />
            </div>
          </div>
        </div>
        <div className="mt-5 flex justify-end gap-2">
          <Button
            buttonFunction={() => {
              close();
            }}
            buttonIcon={"fa-light fa-close"}
            buttonIconPosition={"left"}
            buttonType="button"
            buttonLabel={"Close"}
          />
          <Button
            buttonIcon={
              complete
                ? "fa-light fa-key"
                : "fa-duotone fa-spinner-third animate-spin"
            }
            buttonIconPosition={"left"}
            buttonType="submit"
            buttonLabel={"Change Password"}
          />
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
