import React, { useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import Button from "../components/form/Button";
import Input from "../components/form/Input";
import useForm from "../../src/hooks/useForm";
import { formValidate } from "../helpers/formValidate";
import { login } from "../service/userService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const initialState = {
  email: "",
  password: "",
  remember: "",
};
const validation = {
  email: { required: true, message: "Email is required" },
  password: { required: true, message: "Password is required" },
};

const Login = () => {
  setTitle("Journal 365 | Login");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm(initialState, validation);

  const onSubmit = async (value) => {
    setLoading(true);
    login(value).then((res) => {
      if (res?.status === 200) {
        localStorage.setItem("accessToken", res?.accessToken);
        window.dispatchEvent(new Event("storage"));
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
      setLoading(false);
    });
  };

  return (
    <>
      <div className="w-full h-screen flex justify-center items-center bg-slate-100">
        <div className="w-full max-w-sm bg-white rounded shadow-md py-8 px-6">
          <div className="divide-y divide-slate-200 space-y-4">
            <div className="relative">
              <div className="w-[250px] mx-auto mb-4">
                <img
                  src={require("../assets/images/logo/JOURNAL-COLOR.png")}
                  alt="logo"
                  className="w-full h-full object-center object-contain"
                />
              </div>
              {/* <h1 className="text-indigo-900 font-bold text-center text-3xl capitalize">
                Login
              </h1> */}
              <div className="text-sm text-indigo-900 text-center font-medium">
                Please use your credentials to login
              </div>
            </div>
            <form
              className="pt-4 space-y-4"
              onSubmit={(event) => handleSubmit(event, onSubmit)}
            >
              <div className="relative">
                <Input
                  value={values?.email}
                  inputName={"email"}
                  label={"Email"}
                  labelClasses={"!text-xs"}
                  inputType={"email"}
                  inputPlaceholder={"Email address"}
                  onChange={handleChange}
                  {...formValidate(errors, "email")}
                />
              </div>
              <div className="relative">
                <Input
                  value={values?.password}
                  inputName={"password"}
                  label={"Password"}
                  labelClasses={"!text-xs"}
                  inputType={"password"}
                  inputPlaceholder={"Your Password"}
                  onChange={handleChange}
                  {...formValidate(errors, "password")}
                />
              </div>

              <div className="relative flex justify-center">
                <Button
                  disabled={loading}
                  buttonType={"submit"}
                  buttonIcon={
                    loading
                      ? "fa-duotone fa-spinner-third animate-spin"
                      : "fa-light fa-arrow-right-to-arc"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={"Login"}
                  buttonHasLink={false}
                />
              </div>

              {/* <div className="flex items-center justify-center gap-x-1 text-sm">
                <span>Don't have an account</span>
                <button
                  type="button"
                  className="text-indigo-900 hover:text-indigo-600 font-semibold"
                  onClick={()=>navigate("/register")}
                >
                  Signup
                </button>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
