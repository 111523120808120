import React, { useState } from "react";
import DatePicker from "../form/Datpicker";
import Input from "../form/Input";
import Button from "../form/Button";

const EventAndBirthdayModal = ({
  onSave = () => {},
  onClose = () => {},
  data = {},
  loading = false,
  selectedYear,
  selectedMonth,
}) => {
  const [openDatePicker, setOpenDatepicker] = useState(false);
  const [formData, setFormData] = useState({});

  function onChangeHandler(e) {
    const name = e.target.name;
    const value = e.target.value;
    setFormData((pre) => ({ ...pre, [name]: value }));
  }

  return (
    <div>
      <div className="flex flex-col gap-2">
        <div>
          <DatePicker
            inputName="date"
            label={"Date And Time"}
            placeholder={"Select date time"}
            onChange={onChangeHandler}
            dateFormat={"Y-m-d H:i"}
            inputBodyClass={"!rounded"}
            // startOfMonth={new Date(selectedYear, selectedMonth, 1)}
            // endOfMonth={new Date(selectedYear, selectedMonth + 1, 0)}
          />
        </div>
        <div>
          <Input
            label={"Title"}
            inputName={"name"}
            onChange={onChangeHandler}
            inputPlaceholder={"Enter title"}
            inputClasses={"rounded"}
          />
        </div>
      </div>
      <div
        className={`flex items-center justify-end gap-2 ${
          openDatePicker ? "mt-auto" : "mt-5"
        }`}
      >
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"Cancel"}
          buttonFunction={onClose}
        />
        <Button
          buttonIconPosition={"left"}
          buttonIcon={
            loading
              ? "fa-duotone fa-spinner-third animate-spin"
              : "fa-regular fa-save"
          }
          buttonLabel={"Add Event"}
          buttonFunction={() =>
            onSave({
              ...formData,
              date: formData?.date,
            })
          }
        />
      </div>
    </div>
  );
};

export default EventAndBirthdayModal;
