import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../form/Button";
import NotesItem from "./NotesItem";
import {
  addNoteAndTaskInMonth,
  getMonthList,
} from "../../../service/monthService";
import moment from "moment";
import { toast } from "react-toastify";
import DeleteModal from "../../common/DeleteModal";
import NoData from "../../../assets/images/bg/NoData.svg";
import NotesLoader from "../../loader/notes/NotesLoader";
import Modal from "../../elements/Modal";
import EventAndBirthdayModal from "../../modal/EventAndBirthdayModal";
import { months } from "../../../constant";
import DatePicker from "react-flatpickr";
import { calculateAge } from "../../../helpers";

const EventsNote = ({
  title = "",
  type = "",
  selectedYear,
  selectedMonth,
  abbBtnTooltipLabel = "",
}) => {
  const scrollSec = useRef(null);
  let previousTaskHeight = 0;
  const [loaded, setLoaded] = useState(true);
  const [itemDelete, setItemDelete] = useState({
    deleteComplete: true,
    openModal: false,
    data: {
      apiEndpoint: "task/delete-task",
      targetId: undefined,
      method: "post",
    },
  });
  const [complete, setComplete] = useState({
    index: null,
    noteIndex: null,
    isComplete: true,
  });
  const [content, setContent] = useState([
    // {
    //   day: moment(new Date()).format("DD"),
    //   task: [],
    // },
  ]);
  const [eventModal, setEventModal] = useState({
    isOpen: false,
  });

  const getData = useCallback(() => {
    if (selectedYear && selectedMonth) {
      setLoaded(false);
      getMonthList({
        type: "monthly",
        subType: type,
        year: selectedYear?.toString(),
        month: (selectedMonth + 1)?.toString(),
      }).then((res) => {
        if (res?.docs?.status === 200) {
          if (
            res?.docs?.data &&
            Array.isArray(res?.docs?.data) &&
            res?.docs?.data?.length > 0
          ) {
            setContent(
              res?.docs?.data.sort((a, b) => Number(a.day) - Number(b.day))
            );
          } else {
            setContent([]);
          }
        }
        setLoaded(true);
      });
    }
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    getData();
  }, [getData]);

  // const addNote = () => {
  //   setEventModal({ isOpen: true });
  //   // if (complete?.isComplete) {
  //   //   const today = moment().format("DD");
  //   //   const todayElementIndex = content.findIndex(
  //   //     (element) => element.day === today
  //   //   );
  //   //   if (todayElementIndex !== -1) {
  //   //     setContent((prevContent) => {
  //   //       const new_arr = [...prevContent];
  //   //       new_arr[todayElementIndex].task.push({ name: "" });
  //   //       return new_arr;
  //   //     });
  //   //   } else {
  //   //     setContent((prevContent) => {
  //   //       return [
  //   //         ...prevContent,
  //   //         {
  //   //           day: today,
  //   //           task: [{ name: "" }],
  //   //         },
  //   //       ];
  //   //     });
  //   //   }
  //   // }
  // };

  const addEventHandler = (data) => {
    const datetime = data && Array.isArray(data?.date) && data?.date[0];
    const date = new Date(
      selectedYear,
      selectedMonth,
      Number(moment(new Date()).format("DD"))
    );

    if (datetime) {
      const day = moment(date).format("DD");
      const time = new Date(date).toLocaleTimeString();
      saveNote({ ...data, date, day, time, datetime });

      // const todayElementIndex = content.findIndex(
      //   (element) => element.day === today
      // );
      // if (todayElementIndex !== -1) {
      //   setContent((prevContent) => {
      //     const new_arr = [...prevContent];
      //     new_arr[todayElementIndex].task.push({ name: "" });
      //     return new_arr;
      //   });
      // } else {
      //   setContent((prevContent) => {
      //     return [
      //       ...prevContent,
      //       {
      //         day: today,
      //         task: [{ name: "" }],
      //       },
      //     ];
      //   });
      // }
    }
  };

  useEffect(() => {
    if (scrollSec.current) {
      function scrollBottom() {
        if (previousTaskHeight < scrollSec.current.scrollHeight) {
          scrollSec.current.scrollTop =
            scrollSec.current.scrollHeight - scrollSec.current.clientHeight;
          previousTaskHeight = scrollSec.current.scrollHeight;
        }
      }
      setTimeout(() => {
        scrollBottom();
      }, 200);
      clearTimeout();
    }
  }, [content?.map((item) => item?.task)?.flat(Infinity)?.length]);

  const saveNote = (data) => {
    const { day, time, parent } = data;
    const new_data = {
      type: "monthly",
      subType: type,
    };

    if (data?._id) {
      new_data["name"] = data?.name;
      new_data["taskElementId"] = data?._id;
      new_data["date"] = moment(
        new Date(
          Number(parent?.year),
          Number(parent?.month) - 1,
          Number(parent?.day)
        )
      ).format("YYYY-MM-DD");
    } else {
      new_data["task[0][name]"] = data?.name;
      new_data["task[0][dateTime]"] = data?.datetime;
      new_data["date"] = moment(new Date()).format("YYYY-MM-DD");
    }

    setComplete({
      index: data?.index,
      noteIndex: data?.noteIndex,
      isComplete: false,
    });

    addNoteAndTaskInMonth(new_data).then((res) => {
      if (res?.status === 201 || res?.status === 200) {
        const dayElementIndex = content.findIndex(
          (element) => element?.day === day
        );

        if (res?.status == 201) {
          if (dayElementIndex != -1) {
            setContent((pre) => {
              pre[dayElementIndex].task = res?.data?.task;
              return [...pre];
            });
          } else {
            setContent((pre) => [
              ...pre,
              {
                _id: res?.data?._id,
                type: res?.data?.type,
                year: res?.data?.year,
                month: res?.data?.month,
                day,
                task: res?.data?.task,
              },
            ]);
          }
          toast.success(res?.message);
        }
        if (res?.status == 200) {
          setContent((pre) =>
            pre?.map((item) =>
              item?.day == res?.data?.day
                ? {
                    ...res?.data,
                    task: res?.data?.task,
                  }
                : item
            )
          );
        }
        setEventModal({ isOpen: false });
      } else {
        toast.error(res?.message);
      }
      setComplete({
        index: null,
        noteIndex: null,
        isComplete: true,
      });
    });
  };

  const removeHandler = (data) => {
    if (data?.data?._id) {
      setItemDelete((pre) => ({
        ...pre,
        openModal: true,
        data: {
          apiEndpoint: "task/delete-task",
          targetId: undefined,
          method: "put",
          data: data?.data,
          payload: {
            body: {
              entryId: data?.content?._id,
              taskElementId: data?.data?._id,
            },
          },
        },
      }));
    } else {
      const find_content = content.find(
        (item) => item?.day == data?.content?.day
      );
      if (find_content?.task?.length > 1) {
        setContent((pre) => {
          pre[data?.data?.index].task = pre[data?.data?.index].task?.filter(
            (_, i) => i !== data?.data?.taskIndex
          );
          return [...pre];
        });
      } else {
        setContent((pre) =>
          pre?.filter((item) => item?.day !== find_content?.day)
        );
      }
    }
  };

  const getResponseHandlerAfterDelete = (res) => {
    if (res?.data?.status == 200) {
      const updatedContent = content?.map((item, index) => {
        if (index === res?.data?.data?.index) {
          return {
            ...item,
            task: item.task.filter((task) => task._id !== res?.data?.data?._id),
          };
        }
        return item;
      });

      setContent(updatedContent);
    }

    setItemDelete({
      deleteComplete: true,
      openModal: false,
      data: {
        apiEndpoint: "task/delete-task",
        targetId: undefined,
        method: "post",
      },
    });
  };

  return (
    <>
      <div className="border border-indigo-900 rounded-md overflow-hidden shadow-md">
        <div className="relative h-9 text-center flex items-center justify-center bg-indigo-900 text-white font-semibold">
          <span>{title}</span>
          <Button
            buttonType={"button"}
            buttonFunction={() => setEventModal({ isOpen: true })}
            buttonIcon={"fa-regular fa-plus"}
            buttonIconPosition={"left"}
            buttonLabel={""}
            buttonClasses={
              "w-8 h-8 ml-auto absolute top-[1px] right-[1px] bg-transparent"
            }
            tooltip={{
              show: true,
              label: abbBtnTooltipLabel,
              id: abbBtnTooltipLabel.split(" ").join("-"),
            }}
          />
        </div>
        <div
          ref={scrollSec}
          className={`min-h-[100px] p-3 px-4 flex flex-col ${
            loaded ? "gap-y-3" : "gap-y-1"
          } max-h-96 overflow-y-auto scrollbar`}
        >
          {loaded &&
            content &&
            Array.isArray(content) &&
            content?.length > 0 &&
            content?.map((item, i) =>
              item?.task?.map((task, ind) => (
                <NotesItem
                  key={ind?.toString()}
                  index={i}
                  taskIndex={ind}
                  onRemove={(data) => {
                    removeHandler({ data, content: item });
                  }}
                  data={task}
                  loading={
                    complete?.index == i && complete?.noteIndex == ind
                      ? !complete?.isComplete
                      : false
                  }
                  onSave={(value) =>
                    saveNote({ ...value, noteIndex: ind, parent: item })
                  }
                  date={moment(task?.dateTime.split("T")[0]).format(
                    "MM-DD-YYYY"
                  )}
                  saveEmpty={false}
                  headerRender={
                    <div className="flex gap-1 items-center justify-end">
                      <div className="py-[2px] mb-1 flex gap-2 text-xs w-fit px-2 rounded bg-indigo-900 text-white items-center justify-center">
                        <span>
                          <i className="fa-light fa-calendar-days"></i>
                        </span>
                        <span>
                          {moment(task?.dateTime.split("T")[0]).format(
                            "MM-DD-YYYY"
                          )}
                        </span>
                      </div>
                      <div className="py-[2px] mb-1 flex gap-2 text-xs w-fit px-2 rounded bg-indigo-900 text-white items-center justify-center">
                        <span>
                          <i className="fa-regular fa-clock"></i>
                        </span>
                        <span>
                          {new Date(task?.dateTime).toLocaleTimeString()}
                        </span>
                      </div>
                      {type == "birthday" && (
                        <div className="py-[2px] mb-1 flex gap-2 text-xs w-fit px-2 rounded bg-indigo-900 text-white items-center justify-center">
                          <span>
                            <i className="fa-light fa-cake-candles"></i>
                          </span>
                          <span>
                            {calculateAge(
                              moment(task?.dateTime.split("T")[0]).format(
                                "MM-DD-YYYY"
                              )
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                  }
                />
              ))
            )}
          {loaded &&
            content &&
            Array.isArray(content) &&
            content?.map((item) => item?.task).flat(Infinity)?.length == 0 && (
              <div className="w-full h-[90px] flex flex-col items-center justify-center">
                <img src={NoData} alt="no-task" />
                <div className="text-slate-300">No Data</div>
              </div>
            )}
          {!loaded && <NotesLoader />}
        </div>
      </div>
      <DeleteModal
        setIsOpen={() =>
          setItemDelete({
            deleteComplete: true,
            openModal: false,
            data: {
              apiEndpoint: "task/delete-task",
              targetId: undefined,
              method: "post",
            },
          })
        }
        isOpen={itemDelete?.openModal}
        loading={!itemDelete?.deleteComplete}
        data={itemDelete?.data}
        getResponse={getResponseHandlerAfterDelete}
        deleteFunction={() => {
          setItemDelete((pre) => ({
            ...pre,
            deleteComplete: false,
          }));
        }}
      />
      <Modal
        size="md"
        isOpen={eventModal?.isOpen}
        setIsOpen={() => {
          setEventModal({ isOpen: false });
        }}
        title={`Add ${type}`}
      >
        <EventAndBirthdayModal
          onClose={() => setEventModal({ isOpen: false })}
          onSave={addEventHandler}
          loading={!complete?.isComplete}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
        />
      </Modal>
    </>
  );
};

export default EventsNote;
