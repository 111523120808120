import { apiRequest } from "../utils/async/apiRequest";

export const getDailyList = async (params) => {
  try {
    const res = await apiRequest("get", "task/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addNoteAndTaskInDaily = async (params) => {
  try {
    const res = await apiRequest(
      `${params?.taskElementId ? "put" : "post"}`,
      `${params?.taskElementId ? "task/edit-task" : "task/add"}`,
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addSubTaskInDaily = async (params) => {
  try {
    const res = await apiRequest(
      "put",
      `${
        params?.subTaskElementId
          ? "task/edit-subtask"
          : params?.identicalId
          ? "task/edit-all-subtask"
          : "task/add-subtask"
      }`,
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
