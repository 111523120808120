import React, { useCallback, useEffect, useRef, useState } from "react";
import DraggableFileupload from "../form/draggablefileUpload/DraggableFileupload";
import HideableTexteditor from "../elements/HideableTexteditor";
import SectionHeader from "./SectionHeader";
import RenderDaysbyMonth from "./common/RenderDaysbyMonth";
import { addDiary, getDiaryList } from "../../service/diaryService";
import moment from "moment";
import { toast } from "react-toastify";
import LightBox from "../common/LightBox";
import { removeTag } from "../../helpers";

const DiarySection = ({
  selectedMonth = new Date().getMonth(),
  selectedYear = "",
}) => {
  const targetRef = useRef(null);
  const scrollDiv = useRef(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [image, setImage] = useState([]);
  const [activeButton, setActiveButton] = useState(
    new Date().getDate() || null
  );
  const [haveData, setHaveData] = useState([]);
  const [daysArray, setDaysArray] = useState([]);
  const [diary, setDiary] = useState({
    loaded: true,
    data: [{ name: "", _id: 1 }],
  });

  useEffect(() => {
    // Scroll to the target element when the component mounts
    if (new Date().getDate() == activeButton) {
      if (targetRef.current && scrollDiv.current) {
        scrollDiv.current.scrollTop = targetRef.current.offsetTop - 112;
      }
    }
  }, [daysArray?.length, activeButton]);

  const getCurrentDate = (monthIndex, day) => {
    const year = selectedYear;
    const currentMonth = monthIndex;
    const currentDate = new Date(year, currentMonth, day).getDay();
    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][currentDate];
  };

  const getData = useCallback(() => {
    setImage([]);
    setDiary({ loaded: false, data: [{ name: "", _id: 1 }] });
    getDiaryList({
      type: "diary",
      year: selectedYear?.toString(),
      month: (selectedMonth + 1)?.toString(),
      day: activeButton?.toString(),
    }).then((res) => {
      if (res?.status === 200) {
        setHaveData(res?.occupiedDay);
        setDiary({
          loaded: true,
          data:
            res?.data?.task && res?.data?.task?.length > 0
              ? res?.data?.task
              : [{ name: "", _id: 1 }] || [{ name: "", _id: 1 }],
        });
      } else {
        setDiary({ loaded: true, data: [{ name: "", _id: 1 }] });
      }
    });
  }, [selectedYear, selectedMonth, activeButton]);

  useEffect(() => {
    getData();
  }, [getData]);

  function taskAddOrEdit(data) {
    if (uploadPercentage > 0) {
      toast.info("Please wait previous action not end.");
      return;
    }
    addDiary(data, setUploadPercentage).then((res) => {
      if (res) {
        setUploadPercentage(0);
        if (res?.status === 200 || res?.status === 201) {
          Object?.keys(data)?.forEach((item) => {
            if (
              (item?.includes("name") && removeTag(data[item])) ||
              item?.includes("image")
            ) {
              if (!haveData?.includes(Number(res?.data?.day))) {
                setHaveData((pre) => [...pre, Number(res?.data?.day)]);
              }
            }
            if (
              item?.includes("name") &&
              !removeTag(data[item]) &&
              !item?.includes("image")
            ) {
              if (haveData?.includes(Number(res?.data?.day))) {
                setHaveData(
                  haveData?.filter((item) => item != Number(res?.data?.day))
                );
              }
            }
          });

          if (removeTag(data?.[`task[0][name]`])) {
            if (!haveData?.includes(Number(res?.data?.day))) {
              setHaveData((pre) => [...pre, Number(res?.data?.day)]);
            }
          } else {
            setHaveData(
              haveData?.filter((item) => item != Number(res?.data?.day))
            );
          }
          setDiary({
            loaded: true,
            data: res?.data?.task
              ? res?.data?.task?.length > 0
                ? res?.data?.task?.map((item) => ({
                    ...item,
                    image: item?.image?.map(
                      (img) => "https://api.journal.iosx.in/" + img
                    ),
                  }))
                : [{ name: "", _id: 1 }]
              : [{ name: "", _id: 1 }],
          });
          setImage(
            res?.data?.task
              ?.find((item) => item?.image)
              ?.image?.map((img) => "https://api.journal.iosx.in/" + img)
          );
          toast.success(res?.message);
          return;
        }
        toast.info(res?.message);
      }
    });
  }

  const addEditTask = (values) => {
    const data = {
      type: "diary",
      "task[0][name]": values,
      date: moment(new Date(selectedYear, selectedMonth, activeButton)).format(
        "YYYY-MM-DD"
      ),
    };
    if (image && Array.isArray(image) && image?.length > 0) {
      image?.forEach((file, i) => {
        data[`task[0][image][${i}]`] =
          typeof file == "string" ? file.split(".in/")[1] : file;
      });
    } else {
      diary?.data[0]?.image?.forEach((file, i) => {
        data[`task[0][image][${i}]`] = file.split(".in/")[1];
      });
    }
    taskAddOrEdit(data);
  };

  const onImageRemove = (imageData) => {
    const data = {
      type: "diary",
      date: moment(new Date(selectedYear, selectedMonth, activeButton)).format(
        "YYYY-MM-DD"
      ),
    };
    diary?.data?.forEach((item, i) => {
      data[`task[${i}][name]`] = item?.name;
      if (imageData && Array.isArray(imageData) && imageData?.length > 0) {
        imageData?.forEach((file, index) => {
          data[`task[${i}][image][${index}]`] =
            typeof file == "string" ? file.split(".in/")[1] : file;
        });
      }
    });
    taskAddOrEdit(data);
  };

  useEffect(() => {
    if (
      image &&
      Array.isArray(image) &&
      image?.length > 0 &&
      image?.some((item) => item instanceof File)
    ) {
      const data = {
        type: "diary",
        date: moment(
          new Date(selectedYear, selectedMonth, activeButton)
        ).format("YYYY-MM-DD"),
      };
      diary?.data?.forEach((item, i) => {
        data[`task[${i}][name]`] = item?.name;
        if (image && Array.isArray(image) && image?.length > 0) {
          image?.forEach((file, index) => {
            if (typeof file == "string") {
              data[`task[${i}][image][${index}]`] = file?.split(".in/").at(-1);
            } else {
              data[`task[${i}][image][${index}]`] = file;
            }
          });
        }
      });

      taskAddOrEdit(data);
    }
  }, [image]);

  return (
    <div className="w-full flex gap-3">
      <div
        className="w-32 min-w-[8rem] max-w-[8rem] bg-white p-2 rounded-md h-[calc(100vh-140px)] overflow-y-auto scrollbar scroll-smooth"
        ref={scrollDiv}
      >
        <div className="rounded-md overflow-hidden bg-indigo-900 sticky top-0 mb-1 z-30">
          <div className="h-full w-full px-1 py-2 space-y-0.5 group-odd:bg-indigo-100 group-even:bg-indigo-50 day">
            <div className={`date text-lg text-center font-bold text-white`}>
              {activeButton}
            </div>
            <div
              className={`day-label text-sm text-center text-white font-bold tracking-wider`}
            >
              {getCurrentDate(selectedMonth, activeButton)}
            </div>
          </div>
        </div>
        <RenderDaysbyMonth
          monthIndex={selectedMonth}
          getCurrentDate={getCurrentDate}
          setActiveButton={setActiveButton}
          activeButton={activeButton}
          daysArray={daysArray}
          setDaysArray={setDaysArray}
          targetRef={targetRef}
          haveData={haveData}
        />
      </div>

      <div className="w-full space-y-3 flex-grow bg-white h-fit p-6 rounded-md">
        {diary &&
          Array?.isArray(diary?.data) &&
          diary?.data?.map((data, i) => (
            <>
              <div className="w-full relative">
                <SectionHeader
                  title={"Diary"}
                  activeDate={activeButton}
                  selectedMonth={selectedMonth}
                  selectedYear={selectedYear}
                />

                <HideableTexteditor
                  key={i?.toString()}
                  data={data}
                  loading={!diary?.loaded}
                  onSubmit={(val) => addEditTask(val)}
                />
              </div>
              <div className="w-full relative">
                <LightBox
                  options={{
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                  <DraggableFileupload
                    values={data?.image ? data?.image : []}
                    multiple={true}
                    accept={["image/png", "image/jpeg", "image/jpg"]}
                    max={1}
                    onChange={(val) => {
                      setImage(val);
                    }}
                    uploadPercentage={uploadPercentage}
                    onRemove={(data) => {
                      onImageRemove(data);
                    }}
                    fancyBox={true}
                  />
                </LightBox>
              </div>
            </>
          ))}
      </div>
    </div>
  );
};

export default DiarySection;
