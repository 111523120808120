import React from 'react';

const Progress = ({ progress }) => {
  return (
    <div className="w-full h-2 rounded bg-indigo-300/60">
      <div
        className="h-full bg-indigo-500 rounded ease-in-out duration-300 transition-all"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export default Progress;
