import React, { useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { storeProfileData } from "../redux/slice/profileSlice";
import { useDispatch } from "react-redux";
import { profile } from "../service/userService";

const FreeAuthRoute = ({ children }) => {
  const dispatch = useDispatch()
  const [isLogin, setIslogin] = useState(false);

  window.addEventListener("storage", (e) => {
    const token = e.target.localStorage.accessToken;
    if (token) {
      setIslogin(true);
      profile().then((res) => {
        if (res?.status === 200) {
          dispatch(storeProfileData(res?.data));
        }
      });
    } else {
      setIslogin(false);
    }
  });

  if (localStorage.getItem("accessToken") || isLogin) {
    return <Navigate to="/dailyhighlights" />;
  }
  return children;
};

export default FreeAuthRoute;
