import React, { useEffect } from "react";

const daysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const RenderDaysbyMonth = ({
  haveData = [],
  targetRef,
  monthIndex = 0,
  activeButton = new Date().getDate() || null,
  selectedYear = new Date().getFullYear(),
  daysArray = [],
  getCurrentDate = () => {},
  setActiveButton = () => {},
  setDaysArray = () => {},
}) => {
  const daysCount = daysInMonth(selectedYear, monthIndex);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentDay = currentDate.getDate();

  useEffect(() => {
    setDaysArray(Array.from({ length: daysCount }, (_, i) => i + 1));
  }, [daysCount]);

  return daysArray.map((day, i) => {
    const isCurrentDate =
      selectedYear === currentYear &&
      monthIndex === currentMonth &&
      day === currentDay;
    const isActiveButton = activeButton === day;

    const ref_attr = {};
    if (currentMonth === monthIndex && currentDay === day) {
      ref_attr[`ref`] = targetRef;
    }

    return (
      <div
        key={i?.toString()}
        className={`rounded-md overflow-hidden mb-1 relative main-div`}
        {...ref_attr}
      >
        <button
          className={`h-full w-full px-1 py-2 space-y-0.5 group-odd:bg-indigo-100 group-even:bg-indigo-50 day ${
            getCurrentDate(monthIndex, day) === "Sat" ||
            getCurrentDate(monthIndex, day) === "Sun"
              ? "weekend"
              : ""
          } ${
            currentMonth === monthIndex && currentDay === day
              ? "bg-indigo-900"
              : ""
          } ${isActiveButton ? "bg-indigo-200 " : ""}`}
          onClick={() => setActiveButton(day)}
        >
          <div
            className={`date text-lg text-center font-bold ${
              isCurrentDate && !isActiveButton
                ? "text-white"
                : "text-indigo-900"
            }`}
          >
            {day}
          </div>
          <div
            className={`day-label text-sm text-center  ${
              isCurrentDate && !isActiveButton
                ? "text-white"
                : "text-indigo-900"
            } font-bold tracking-wider`}
          >
            {getCurrentDate(monthIndex, day)}
          </div>
        </button>
        {haveData?.includes(day) && (
          <span className="flex w-2 h-2 rounded-full bg-indigo-500 absolute top-1/2 right-2 -translate-y-1/2"></span>
        )}
      </div>
    );
  });
};

export default RenderDaysbyMonth;
