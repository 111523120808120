import React, { useEffect, useMemo, useState } from "react";

const Radio = ({
  radioName,
  radioInputClass,
  isChecked,
  radioLabel,
  radioLableClass,
  onChange = () => {},
  value,
  checked,
  ...props
}) => {
  const [checkedRadio, setCheckedRadio] = useState(false);

  useMemo(() => {
    setCheckedRadio(checked);
  }, [checked]);


  return (
    <>
      <div className="relative overflow-hidden flex items-center">
        <input
          type="radio"
          name={radioName}
          className={
            "peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer " +
            radioInputClass
          }
          value={value}
          checked={checkedRadio}
          onChange={(e) => {
            onChange(e);
            setCheckedRadio(e.target.checked ? true : false);
          }}
        />
        <div className="w-5 h-5 min-w-[20px] mr-3 border border-slate-300 rounded-full flex items-center justify-center text-[10px] bg-white text-black/0 peer-checked:bg-indigo-500 peer-checked:border-indigo-500 peer-checked:text-white">
          {/* <i className="fa-solid fa-fw fa-circle mt-[1px]"></i> */}
          <span className="flex w-3 h-3 rounded-full bg-white"></span>
        </div>
        <div className={"text-sm text-slate-500 w-full " + radioLableClass}>
          {radioLabel}
        </div>
      </div>
    </>
  );
};

export default Radio;
