import { apiRequest } from "../utils/async/apiRequest";

export const getMonthList = async (params) => {
  try {
    const res = await apiRequest("get", "task/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addNoteAndTaskInMonth = async (params) => {
  try {
    const res = await apiRequest(
      `${params?.taskElementId ? "put" : "post"}`,
      `${params?.taskElementId ? "task/edit-task" : "task/add"}`,
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const listByGoalType = async (params) => {
  try {
    const res = await apiRequest(`get`, `task/dailygoal-list`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const progressList = async (params) => {
  try {
    const res = await apiRequest(`get`, `task/view-progress`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
