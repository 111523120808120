import React, { useEffect, useState } from "react";
import Input from "../../form/Input";
import Button from "../../form/Button";
import Radio from "../../form/Radio";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { inputNumber } from "../../../helpers";

let validation = {
  name: { required: true, message: "Goal name is required" },
};

const AddTask = ({
  cancel = () => {},
  saveHandler = () => {},
  editData = {},
  title = "Add Task",
  loading = false,
}) => {
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({ goalType: "unquantifiable", name: "" }, validation);

  useEffect(() => {
    return () => {
      delete validation?.totalTime;
      validation = { ...validation };
    };
  }, []);

  useEffect(() => {
    if (editData && Object?.keys(editData)?.length > 0) {
      setFieldsValue({
        name: editData?.label,
        totalTime: editData?.totalTime,
        goalType: editData?.goalType,
      });
    }
  }, [editData]);

  const onSubmit = (value) => {
    saveHandler({ data: value, resetField: resetField });
  };

  return (
    <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
      <div className="flex gap-2">
        <Radio
          checked={values?.goalType == "quantifiable"}
          value="quantifiable"
          radioName={"goalType"}
          radioLabel={"Quantifiable"}
          onChange={(e) => {
            validation[`totalTime`] = {
              required: true,
              message: "target is required",
            };
            handleChange(e);
          }}
        />
        <Radio
          checked={values?.goalType == "unquantifiable"}
          value="unquantifiable"
          radioName={"goalType"}
          radioLabel={"Unquantifiable"}
          onChange={(e) => {
            if (validation?.totalTime) {
              delete validation?.totalTime;
            }
            handleChange(e);
          }}
        />
      </div>
      {values?.goalType == "quantifiable" && (
        <div className="my-5">
          <Input
            inputName={"totalTime"}
            value={values?.totalTime}
            inputClasses={"rounded"}
            inputPlaceholder={"Set a target"}
            onChange={(e) => {
              handleChange(inputNumber(e));
            }}
            {...formValidate(errors, "totalTime")}
          />
        </div>
      )}
      <div className="my-5">
        <Input
          inputName={"name"}
          value={values?.name}
          inputClasses={"rounded"}
          inputPlaceholder={"Enter goal name"}
          onChange={handleChange}
          {...formValidate(errors, "name")}
        />
      </div>
      <div className="flex justify-end mt-3 gap-2">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"Cancel"}
          buttonFunction={cancel}
        />
        <Button
          disabled={loading}
          buttonType="submit"
          buttonIconPosition={"left"}
          buttonIcon={`${
            !loading
              ? "fa-regular fa-save"
              : "fa-duotone fa-spinner-third animate-spin transition-all"
          }`}
          buttonLabel={"Save Goal"}
          // buttonFunction={() => saveHandler(taskName)}
        />
      </div>
    </form>
  );
};

export default AddTask;
