import moment from "moment";
import React from "react";

const DefaultFooter = () => {
  const year = moment(new Date()).format("YYYY");
  return (
    <div className="bg-indigo-900 p-3">
      <div className="text-white text-center">
        &copy; 2024{year !== "2024" && ` - ${year}`} {window.location.host}
      </div>
    </div>
  );
};

export default DefaultFooter;
