import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AuthLayout from "./layouts/AuthLayout";
import DefaultLayout from "./layouts/DefaultLayout";
import Login from "./pages/Login";
import DailyGoal from "./pages/DailyGoal";

import MonthlyGoal from "./pages/MonthlyGoal";
import Diary from "./pages/Diary";
import ProtectedRoute from "./routes/ProtectedRoute";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import Signup from "./pages/Signup";
import Plans from "./pages/Plans";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route
              path="/"
              exact
              element={
                <FreeAuthRoute>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          {/* <Route path="/register" element={<AuthLayout />}>
            <Route
              path="/register"
              exact
              element={
                <FreeAuthRoute>
                  <Signup />
                </FreeAuthRoute>
              }
            />
          </Route> */}
          <Route path="/dailyhighlights" element={<DefaultLayout />}>
            <Route
              path="/dailyhighlights"
              exact
              element={
                <ProtectedRoute>
                  <DailyGoal />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/mymonth" element={<DefaultLayout />}>
            <Route
              path="/mymonth"
              exact
              element={
                <ProtectedRoute>
                  <MonthlyGoal />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/diary" element={<DefaultLayout />}>
            <Route
              path="/diary"
              exact
              element={
                <ProtectedRoute>
                  <Diary />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/plans" element={<DefaultLayout />}>
            <Route path="/plans" exact element={<Plans />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
