import React from "react";

const Checkbox = ({
  checkboxName,
  checkboxInputClass,
  isChecked,
  checked,
  disabled = false,
  onChange = () => {},
  checkboxLabel,
  checkboxLableClass,
  ...props
}) => {
  return (
    <>
      <div className="relative overflow-hidden flex items-center">
        <input
          type="checkbox"
          onChange={(e) => {
            onChange(e.target.checked);
          }}
          disabled={disabled}
          name={checkboxName}
          className={
            "peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer  " +
            checkboxInputClass
          }
          checked={checked}
          defaultChecked={isChecked}
        />
        <div
          className={`w-5 h-5 min-w-[20px] ${
            checkboxLabel ? "mr-3" : ""
          } border border-indigo-500 rounded flex items-center justify-center text-sm bg-white text-black/0 peer-checked:bg-indigo-900 peer-checked:border-indigo-500 peer-checked:text-white flex-shrink`}
        >
          <i className="fa-light fa-fw fa-check"></i>
        </div>
        <div
          className={
            "text-sm text-slate-500 w-full flex-shrink-0 " + checkboxLableClass
          }
        >
          {checkboxLabel}
        </div>
      </div>
    </>
  );
};

export default Checkbox;
