import React, { useCallback, useEffect, useState } from "react";
import RenderdayByMonths from "./dailyGoals/RenderdayByMonths";
import { months } from "../../constant";
import { getDailyList } from "../../service/dailyService";
import moment from "moment";

const daysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
};

const DailyGoalSection = ({ selectedMonth, selectedYear }) => {
  const [noteAndTask, setNoteAndTask] = useState([]);
  const daysCount = daysInMonth(selectedYear, selectedMonth);
  const calender = {
    today: moment(new Date()).format("DD"),
    currentMonth: new Date().getMonth(),
  };

  useEffect(() => {
    setNoteAndTask(
      Array.from({ length: daysCount }, (_, i) => i + 1)?.map((day, i) => ({
        day,
        note: "",
        tasks: [],
      }))
    );
  }, [daysCount, selectedMonth]);

  const getData = useCallback(() => {
    getDailyList({
      type: "daily",
      year: selectedYear?.toString(),
      month: (selectedMonth + 1)?.toString(),
    }).then((res) => {
      if (res && res?.docs?.status == 200) {
        const updatedDaysMap = new Map();
        res?.docs?.data.forEach((updatedDay) => {
          updatedDaysMap.set(updatedDay.day, {
            ...updatedDay,
            day: Number(updatedDay.day),
            note: updatedDay.task[0]?.name,
            taskId: updatedDay.task[0]?._id,
            tasks: updatedDay?.task[0]?.subTask?.map((item) => ({
              ...item,
              label: item?.name,
            })),
          });
        });

        setNoteAndTask((prevDays) => {
          updatedDaysMap?.forEach((item) => {
            delete item.task;
            prevDays[Number(item?.day - 1)] = item;
          });
          return [...prevDays];
        });
      }
    });
  }, [selectedYear, selectedMonth, daysCount]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getCurrentDate = (monthIndex, day) => {
    const year = selectedYear;
    const currentMonth = monthIndex;
    const currentDate = new Date(year, currentMonth, day).getDay();

    return [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ][currentDate];
  };

  return (
    <>
      <div className="calendar w-full space-y-2">
        <div className="flex items-center justify-center">
          <div className="text-indigo-900 font-bold text-center text-3xl capitalize">
            {months[selectedMonth]} {selectedYear}
            {/* Daily Highlights */}
          </div>
        </div>
        <div className="w-full">
          <RenderdayByMonths
            getCurrentDate={getCurrentDate}
            monthIndex={selectedMonth}
            selectedYear={selectedYear}
            note={noteAndTask}
            calender={calender}
            setNoteAndTask={setNoteAndTask}
          />
        </div>
      </div>
      
    </>
  );
};

export default DailyGoalSection;
