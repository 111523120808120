import VerticalTab from "../common/VerticalTab";
import Profile from "../sections/Profile";
import ChangePassword from "../sections/ChangePassword";

const ProfileModal = ({ close = () => {} }) => {
  const tabData = [
    {
      label: "Profile",
      icon: "fa-regular fa-user",
      component: <Profile close={close} />,
    },
    {
      label: "Change Password",
      icon: "fa-regular fa-key",
      component: <ChangePassword close={close} />,
    },
  ];
  return (
    <>
      <VerticalTab tabData={tabData} />
    </>
  );
};

export default ProfileModal;
