import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { profile } from "../service/userService";
import { useDispatch } from "react-redux";
import { storeProfileData } from "../redux/slice/profileSlice";

const ProtectedRoute = (props) => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [auth, setAuth] = useState(false);
  let token_ = localStorage.getItem("accessToken");

  window.addEventListener("storage", (e) => {
    const token = e.target.localStorage.accessToken;
    if (token) {
      setAuth(true);
    } else {
      setAuth(false);
      navigate("/");
      return;
    }
  });

  useEffect(() => {
    if (token_ || auth) {
      profile().then((res) => {
        if (res?.status === 200) {
          dispatch(storeProfileData(res?.data));
        }
      });
    }
  }, [token_]);

  useEffect(() => {
    async function fetchData() {
      if (token_) {
        setAuth(true);
        return;
      } else {
        setAuth(false);
        localStorage.removeItem("accessToken");
        navigate("/");
        return;
      }
    }
    fetchData();
  }, []);

  return <>{auth && props.children}</>;
};

export default ProtectedRoute;
