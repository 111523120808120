import React, { useEffect, useState } from "react";
import Input from "../form/Input";
import Textarea from "../form/Textarea";
import Button from "../form/Button";
import camera from "../../assets/images/icon/camera.svg";
import useForm from "../../hooks/useForm";
import Select from "../form/Select";
import { useSelector } from "react-redux";
import { updateProfile } from "../../service/userService";
import Progress from "../elements/Progress";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { storeProfileData } from "../../redux/slice/profileSlice";
import DatePicker from "../form/datePicker/DatePicker";
import moment from "moment/moment";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  city: "",
  dateOfBirth: "",
  gender: "",
  state: "",
  country: "",
  zipcode: "",
  address: "",
};

const Profile = ({ close = () => {} }) => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [updatePer, setUpdatePer] = useState(null);
  const profile = useSelector((state) => state?.profile?.data);
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm(initialState);

  useEffect(() => {
    if (profile) {
      setFieldsValue({
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        email: profile?.email,
        image: profile?.image,
        phone: profile?.phone,
        dateOfBirth: moment(profile?.dateOfBirth).format("YYYY-MM-DD"),
        city: profile?.address[0]?.city,
        gender: profile?.gender,
        state: profile?.address[0]?.state,
        country: profile?.address[0]?.country,
        zipcode: profile?.address[0]?.zipcode,
        address: profile?.address[0]?.address1,
      });
    }
  }, [profile]);

  const onSubmit = (values) => {
    const address = {
      city: values?.city || "",
      state: values?.state || "",
      country: values?.country || "",
      zipcode: values?.zipcode || "",
      address1: values?.address || "",
    };
    delete values?.email;
    delete values?.city;
    delete values?.state;
    delete values?.country;
    delete values?.zipcode;
    delete values?.address;
    updateProfile({ ...values, address }, setUpdatePer).then((res) => {
      setUpdatePer(null);
      if (res.status === 200) {
        dispatch(
          storeProfileData({
            ...res?.data,
            image: "https://api.journal.iosx.in/" + res?.data?.image,
          })
        );
        toast.success(res?.message);
        setEditable(false);
      } else {
        toast.error(res?.message);
      }
    });
  };

  return (
    <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
      <div className="relative">
        <div className="flex items-center gap-4">
          <div className="w-20 h-20 border-2 border-indigo-500 rounded-full p-[3px] relative overflow-hidden">
            <div className="w-full h-full rounded-full overflow-hidden">
              <img
                src={
                  values?.image
                    ? values?.image instanceof File
                      ? URL.createObjectURL(values?.image)
                      : values?.image
                    : require("../../assets/images/icon/user.png")
                }
                alt="display-picture"
                className="w-full h-full object-cover object-center"
              />
            </div>
            {editable && (
              <div className="absolute top-0 left-0 w-full h-full cursor-pointer rounded-full overflow-hidden">
                <span className="flex w-5 h-5 bg-white rounded-full absolute bottom-2 right-2 justify-center items-center">
                  <img
                    src={camera}
                    alt="upload"
                    className="w-3 h-3 object-contain object-center"
                  />
                </span>
                <input
                  name="image"
                  onChange={handleChange}
                  type="file"
                  className="w-full h-full opacity-0 cursor-pointer"
                />
              </div>
            )}
          </div>
          <div className="text-2xl font-semibold">
            {profile?.firstName}&nbsp;{profile?.lastName}
          </div>
          <Button
            buttonFunction={() => setEditable(!editable)}
            buttonIconPosition={"left"}
            buttonIcon={"fa-light fa-pen text-xs"}
            buttonClasses={"ml-auto"}
            buttonLabel={"Edit Profile"}
          />
        </div>
        <div className="mt-5 flex flex-col gap-y-2">
          <div className="font-semibold text-lg">General</div>
          <div className="flex gap-2 mt-4">
            <div className="w-full">
              <Input
                isDisabled={!editable}
                inputName={"firstName"}
                inputClasses={"w-full"}
                inputPlaceholder={"Enter firstname"}
                inputType="text"
                // label={editable ? "First name" : ""}
                label={"First name"}
                labelClasses={"!text-indigo-900"}
                value={values?.firstName}
                onChange={handleChange}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
            <div className="w-full">
              <Input
                isDisabled={!editable}
                inputName={"lastName"}
                inputClasses={"w-full"}
                inputPlaceholder={"Enter lastname"}
                inputType="text"
                // label={editable ? "Last name" : ""}
                label={"Last name"}
                labelClasses={"!text-indigo-900"}
                value={values?.lastName}
                onChange={handleChange}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-full">
              <Input
                isDisabled={true}
                inputName={"email"}
                inputClasses={"w-full"}
                inputPlaceholder={"Enter email"}
                inputType="email"
                // label={editable ? "Email" : ""}
                label={"Email"}
                value={values?.email}
                labelClasses={"!text-indigo-900"}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
            <div className="w-full">
              <Input
                isDisabled={!editable}
                inputName={"phone"}
                inputClasses={"w-full"}
                inputPlaceholder={"Enter phone"}
                inputType="text"
                // label={editable ? "Phone" : ""}
                label={"Phone"}
                labelClasses={"!text-indigo-900"}
                value={values?.phone}
                onChange={handleChange}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-full">
              <Select
                isDisabled={!editable}
                selectName="gender"
                placeholder="Select gender"
                // label={editable ? "Gender" : ""}
                label={"Gender"}
                labelClasses={"!text-indigo-900"}
                value={values?.gender}
                dropdownData={[
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                  { label: "Others", value: "others" },
                ]}
                onChange={handleChange}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
            {values?.dateOfBirth && (
              <div className="w-full">
                <DatePicker
                  // label={editable ? "DOB" : ""}
                  label={"DOB"}
                  labelClasses={"!text-indigo-900"}
                  isDisabled={!editable}
                  inputName={"dateOfBirth"}
                  value={values?.dateOfBirth}
                  onChange={handleChange}
                  className={`${!editable ? "preview-input" : ""}`}
                />
              </div>
            )}
          </div>
        </div>
        <div className="mt-5 flex flex-col gap-y-2">
          <div className="font-semibold text-lg">Address</div>
          <div className="flex gap-2 mt-4">
            <div className="w-full">
              <Input
                isDisabled={!editable}
                inputName={"city"}
                inputClasses={"w-full"}
                inputPlaceholder={"Enter city"}
                inputType="text"
                // label={editable ? "City" : ""}
                label={"City"}
                labelClasses={"!text-indigo-900"}
                value={values?.city}
                onChange={handleChange}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
            <div className="w-full">
              <Input
                isDisabled={!editable}
                inputName={"state"}
                inputClasses={"w-full"}
                inputPlaceholder={"Enter state"}
                inputType="text"
                // label={editable ? "State" : ""}
                label={"State"}
                labelClasses={"!text-indigo-900"}
                value={values?.state}
                onChange={handleChange}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-full">
              <Input
                isDisabled={!editable}
                inputName={"country"}
                inputClasses={"w-full"}
                inputPlaceholder={"Select country"}
                inputType="text"
                // label={editable ? "Country" : ""}
                label={"Country"}
                labelClasses={"!text-indigo-900"}
                value={values?.country}
                onChange={handleChange}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
            <div className="w-full">
              <Input
                isDisabled={!editable}
                inputName={"zipcode"}
                inputClasses={"w-full"}
                inputPlaceholder={"Enter zipcode"}
                inputType="text"
                // label={editable ? "Zipcode" : ""}
                label={"Zipcode"}
                labelClasses={"!text-indigo-900"}
                value={values?.zipcode}
                onChange={handleChange}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
          </div>
          {(values?.address || editable) && (
            <div>
              <Textarea
                isDisabled={!editable}
                inputName={"address"}
                inputClasses={"w-full h-20"}
                inputPlaceholder={"Enter full address"}
                inputType="text"
                // label={editable ? "Address" : ""}
                label={"Address"}
                value={values?.address}
                onChange={handleChange}
                className={`${!editable ? "preview-input" : ""}`}
              />
            </div>
          )}
        </div>
        <div className="mt-5 flex justify-end gap-2">
          <Button
            buttonFunction={() => {
              close();
            }}
            buttonIcon={"fa-light fa-close"}
            buttonIconPosition={"left"}
            buttonType="button"
            buttonLabel={"Close"}
          />
          {editable && (
            <Button
              buttonIcon={"fa-light fa-save"}
              buttonIconPosition={"left"}
              buttonType="submit"
              buttonLabel={"Save Profile"}
            />
          )}
        </div>
        {updatePer && updatePer > 0 && (
          <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center">
            <div className="w-[400px] h-32 rounded-lg backdrop-blur-[2px] flex flex-col items-center justify-center p-10 shadow-lg">
              <Progress progress={updatePer} />
              <div className="text-indigo-500 mt-1">
                Please wait profile updating...{updatePer}%
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default Profile;
