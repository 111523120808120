import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Texteditor = ({
  value = "",
  onChange = () => {},
  placeholder = "Write your content....",
  label = "",
  labelClasses = "",
  errorType = "",
  errorText = "",
}) => {
  const values = {};

  const [colors, setColors] = useState([
    "#f0fdf9",
    "#cdfaec",
    "#9bf4db",
    "#61e7c7",
    "#31d0ae",
    "#17b193",
    "#10917b",
    "#117464",
    "#135c52",
    "#144d44",
    "#D1FBFF",
    "#9EF7FF",
    "#00E0F5",
    "#00C0D1",
    "#00919E",
    "#003D42",
    "#00383D",
    "#002A2E",
    "#001C1F",
    "#001C1F",

    "#F2FDF7",
    "#E0FAED",
    "#C6F6DD",
    "#9EF0C6",
    "#72E9AB",
    "#3DE18B",
    "#1FCB72",
    "#1CB566",
    "#179655",
    "#106A3C",

    "#F3FFD1",
    "#E2FF94",
    "#B7FA00",
    "#B0F000",
    "#A5E000",
    "#97CE00",
    "#8ABD00",
    "#78A300",
    "#618500",
    "#476100",
  ]);

  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: colors }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      // ["link", "image", "video"],
      ["link"],
      ["clean"],
    ],
  };

  if (value) {
    values["value"] = value;
  }

  return (
    <>
      {label && (
        <div
          className={"text-sm font-medium text-slate-500 mb-1 " + labelClasses}
        >
          {label}
        </div>
      )}
      <ReactQuill
        placeholder={placeholder}
        theme="snow"
        modules={modules}
        {...values}
        onChange={(val) => {
          onChange(val);
        }}
      />
      {errorType && errorType === "error" && (
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="text-xs text-indigo-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "info" && (
        <div className="text-xs text-sky-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
          {errorText}
        </div>
      )}
    </>
  );
};

export default Texteditor;
