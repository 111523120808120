export const removeTag = (html) => {
  return html?.replace(/<[^>]*>/g, "");
};

export const onlyNumberRegx = /^[01\b]+$/;

export const getOrdinalSuffix = (n) => {
  if (n) {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  }
};

export const calculateAge = (birthDate) => {
  var today = new Date();
  var birthDate = new Date(birthDate);
  var age = today.getFullYear() - birthDate.getFullYear();
  var monthDiff = today.getMonth() - birthDate.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};

export const inputNumber = (e) => {
  const name = e.target.name;
  const newValue = e.target.value.replace(/[^0-9]/g, "");
  return {
    ...e,
    target: { ...e.target, name, value: newValue },
  };
};
