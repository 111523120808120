import React, { useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import DiarySection from "../components/sections/DiarySection";
import CalenderLayout from "../components/sections/CalenderLayout";

const Diary = () => {
  setTitle("Journal 365 | Diary");
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(
    currentYear ? currentYear : ""
  );
  return (
    <>
      <CalenderLayout
        currentYear={currentYear}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        setCurrentYear={setCurrentYear}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
      >
        <DiarySection
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
      </CalenderLayout>
    </>
  );
};

export default Diary;
