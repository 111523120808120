import React, { useCallback, useEffect, useRef, useState } from "react";
import NoData from "../../../assets/images/bg/NoData.svg";
import ProgressItem from "./ProgressItem";

const Note = ({ title = "", content = {}, setContent = () => {} }) => {
  const scrollSec = useRef(null);
  let previousTaskHeight = 0;
  const [eventModal, setEventModal] = useState({
    isOpen: false,
  });

  return (
    <>
      <div className="border border-indigo-900 rounded-md overflow-hidden shadow-md">
        <div className="relative h-9 text-center flex items-center justify-center bg-indigo-900 text-white font-semibold">
          <span>{title}</span>
        </div>
        <div
          className={`min-h-[100px] p-3 px-4 flex flex-col ${
            content?.loaded ? "gap-y-3" : "gap-y-1"
          } max-h-96 overflow-y-auto scrollbar`}
        >
          <div className="flex gap-2 flex-wrap">
            {content &&
              content?.loaded &&
              Array.isArray(content?.data) &&
              content?.data?.length > 0 &&
              content?.data?.map((item, ind) => (
                <ProgressItem
                  key={ind?.toString()}
                  title={item?.name}
                  completeTime={item?.completedTime}
                  totalTime={item?.totalTime}
                />
              ))}
          </div>
          {content &&
            content?.loaded &&
            Array.isArray(content?.data) &&
            content?.data?.length == 0 && (
              <div className="w-full h-[90px] flex flex-col items-center justify-center">
                <img src={NoData} alt="no-task" />
                <div className="text-slate-300">No Data</div>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default React.memo(Note);
