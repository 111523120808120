import React, { useEffect, useRef, useState } from "react";
import Textarea from "../../form/Textarea";
import { removeTag } from "../../../helpers";
import Button from "../../form/Button";
import Dot from "../../loader/dotLoading/Dot";
import { useDebounce } from "../../../hooks/useDebounce";

const NoteInput = ({
  onChange = () => {},
  onSave = () => {},
  data = {},
  taskIndex,
  index,
  saveButton = false,
  loading = false,
  saveEmpty,
}) => {
  const editorRef = useRef(null);
  const [showEditor, setShowEditor] = useState(false);
  const [value, setValue] = useState("");
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    if (showEditor) {
      if (debouncedValue) {
        if (data?.name != debouncedValue && value?.trim()) {
          onSave({ ...data, name: value, index, taskIndex });
          data[`name`] = value;
        }
      }
    }
  }, [debouncedValue]);

  function emptySubmit() {
    if (saveEmpty) {
      if (data?.name) {
        onSave({ ...data, name: "", index, taskIndex });
        data[`name`] = "";
        setValue("");
      }
    }
  }

  useEffect(() => {
    if (!value && !data?.name) {
      setValue(data?.name);
    } else if (!value) {
      setValue(data?.name);
    } else {
      setValue(data?.name);
    }
    if (!data?.name || data?.name == "") {
      setValue("");
      setShowEditor(true);
    }
  }, [data]);

  useEffect(() => {
    if ((removeTag(value) || removeTag(data?.name)) && !debouncedValue) {
      setShowEditor(false);
    } else {
      if (!showEditor) {
        return;
      }
      setShowEditor(true);
    }
  }, [data?.name]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        if (removeTag(value)) {
          setShowEditor(false);
        }
        if (!saveEmpty) {
          if (!value && data?.name) {
            setValue(data?.name);
            setShowEditor(false);
          }
        }
        if (value?.length > 0) {
          if (value?.trim()) {
            if (data?.name != value) {
              onSave({ ...data, name: value, index, taskIndex });
              data[`name`] = value;
            }
          }
        }
        // else {
        //   console.log("last", value, value?.length);
        //   if (data?.name != value) {
        //     onSave({ ...data, name: value, index, taskIndex });
        //     data[`name`] = value;
        //   }
        // }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value]);

  const handleEditorClick = () => {
    if (!showEditor) {
      setShowEditor(true);
    }
  };

  return (
    <div ref={editorRef} className="w-full h-full relative">
      {!showEditor ? (
        <div
          className="h-16 max-h-[64px] overflow-y-auto scrollbar whitespace-normal"
          onClick={() => handleEditorClick()}
        >
          <pre className="whitespace-normals">{value}</pre>
        </div>
      ) : (
        <div className="flex gap-2 items-center">
          <div className="flex-grow h-fit relative">
            <Textarea
              autoFocus={value ? true : false}
              inputPlaceholder={!value ? "Add notes" : ""}
              inputValue={value}
              inputClasses="!h-16 !border-none bg-transparent pr-10"
              onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
                if (!e.target.value) {
                  if (saveEmpty) {
                    emptySubmit();
                  }
                }
              }}
            />
          </div>
          {saveButton && (
            <Button
              buttonLabel={""}
              tooltip={{ show: true, label: "Save" }}
              // buttonIcon={"fa-regular fa-save"}
              buttonIcon={`${
                loading
                  ? "fa-duotone fa-spinner-third animate-spin transition-all"
                  : "fa-light fa-save"
              }`}
              buttonIconPosition={"left"}
              buttonType="button"
              buttonFunction={() => {
                if (saveButton) {
                  onSave({ ...data, name: value, index, taskIndex });
                  data[`name`] = value;
                }
              }}
            />
          )}
        </div>
      )}
      {!saveButton && loading && (
        <div className="absolute bottom-0 right-0 flex flex-col items-center justify-center">
          <span>
            <i className="fa-duotone fa-spinner-third animate-spin transition-all"></i>
          </span>
        </div>
      )}
    </div>
  );
};

export default NoteInput;
