import React from "react";

const ProgressItem = ({ title = "", totalTime = 0, completeTime = 0 }) => {
  return (
    <div className="shadow min-w-[100px] w-fit rounded overflow-hidden cursor-pointer hover:shadow-md">
      <div className="px-3 py-2 bg-indigo-500 text-sm text-white text-center">
        {title}
      </div>
      <div className="px-3 py-2 text-center text-indigo-900 [&>*]:text-sm">
        <div className="flex gap-2">
          <div className="flex items-center gap-2">
            <span className="flex w-2 h-2 bg-green-500 rounded-full"></span>
            <span>Actual Number :</span>
          </div>
          <div>{completeTime}</div>
        </div>
        <div className="flex gap-2">
          <div className="flex items-center gap-2">
            <span className="flex w-2 h-2 bg-green-500 rounded-full"></span>
            <span>Target Number :</span>
          </div>
          <div>{totalTime}</div>
        </div>
      </div>
    </div>
  );
};

export default ProgressItem;
