import axios from "axios";
import { API_BASE_URL } from "../../config/apiConfig";
import { toast } from "react-toastify";

let isRefreshing = false;
let refreshSubscribers = [];

export const apiRequest = async (
  method = "get",
  url = "",
  payload = { body: null, queries: null },
  contentType = "application/json",
  uploadProgress = () => {},
  customHeader = {}
) => {
  try {
    let refresh_token;
    let access_token;
    if (localStorage?.getItem("accessToken")) {
      access_token = localStorage?.getItem("accessToken");
    }
    if (localStorage?.getItem("refreshToken")) {
      refresh_token = localStorage?.getItem("refreshToken");
    }

    const instance = axios.create({
      baseURL: API_BASE_URL,
      headers: {
        "Content-Type": contentType,
        Accept: contentType,
        ...customHeader,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        uploadProgress(percentCompleted);
      },
    });

    // Add a request interceptor
    instance.interceptors.request.use(
      function (config) {
        if (access_token) {
          config.headers[`Authorization`] = "Bearer " + access_token;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    function refreshToken() {
      return new Promise((resolve, reject) => {
        if (refresh_token) {
          instance
            .post("refresh-token", {
              refreshToken: refresh_token,
            })
            .then((res) => {
              if (res?.data?.status === 200) {
                access_token = res?.data?.accessToken;
                refresh_token = res?.data?.refreshToken;
                localStorage.setItem("accessToken", res?.data?.accessToken);
                localStorage.setItem("refreshToken", res?.data?.refreshToken);
                window.dispatchEvent(new Event("storage"));
                resolve(res.data.accessToken);
              } else {
                reject("Token refresh failed");
              }
            })
            .catch((error) => {
              reject(error);
              return;
            });
        } else {
          reject("No refresh token available");
          return;
        }
      });
    }

    // Add a response interceptor
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (error?.response?.data?.inValidToken == true) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          window.dispatchEvent(new Event("storage"));
          toast.info("Please login again");
        }
        if (
          error?.response?.data?.status === 401 &&
          error?.response?.data?.tokenExpired == true &&
          !originalRequest._retry
        ) {
          if (isRefreshing) {
            // Token refresh is in progress, add the request to the queue
            return new Promise((resolve) => {
              refreshSubscribers.push((accessToken) => {
                originalRequest.headers["Authorization"] =
                  "Bearer " + accessToken;
                resolve(instance(originalRequest));
              });
            });
          }

          originalRequest._retry = true;
          isRefreshing = true;

          try {
            const newToken = await refreshToken();
            originalRequest.headers["Authorization"] = "Bearer " + newToken;
            return instance(originalRequest);
          } catch (refreshError) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            window.dispatchEvent(new Event("storage"));
            toast.info("Please login again");
            // Handle token refresh failure, e.g., redirect to login page
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        }

        return Promise.reject(error);
      }
    );

    const res = await instance[method.toLowerCase()](
      `${url}${
        payload.queries ? "?" + new URLSearchParams(payload.queries) : ""
      }`,
      payload.body
    );
    return res;
  } catch (error) {
    return error?.response;
  }
};
