import React, { useEffect, useMemo, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
// import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/airbnb.css";
import { classNames } from "../../helpers/classNames";

const InputDate = ({
  label,
  inputName,
  labelClasses,
  isInputGroup = false,
  inputBodyClass = "",
  inputGroupIcon,
  inputGroupPosition,
  inputGroupIconClass,
  inputClasses,
  errorType,
  errorText,
  isDisabled,
  dateFormat,
  startOfMonth,
  endOfMonth,
  value,
  onChange = () => {},
  ...props
}) => {
  const flatPicker = useRef(null);
  const [focused, setFocused] = useState(false);
  const [dateValue, setDateValue] = useState();
  const [disabled, setDisabled] = useState(true);


  useMemo(() => {
    if (value) {
      setDateValue(new Date(value));
    }
  }, [value]);

  const datePickerProps = {};

  if (startOfMonth) {
    datePickerProps[`minDate`] = startOfMonth;
  }
  if (endOfMonth) {
    datePickerProps[`maxDate`] = endOfMonth;
  }
  if (startOfMonth || endOfMonth) {
    datePickerProps[`nextArrow`] = "";
    datePickerProps[`prevArrow`] = "";
    datePickerProps[`monthSelectorType`] = "";
  }

  return (
    <>
      <div className={`relative space-y-1 w-full`}>
        {label && (
          <div
            className={
              "text-sm font-medium text-slate-500 mb-1 " + labelClasses
            }
          >
            {label}
          </div>
        )}
        <div className={classNames("relative flex")}>
          {isInputGroup && inputGroupPosition === "left" && (
            <div
              className={classNames(
                "w-10 flex-shrink-0 flex items-center justify-center border bg-white text-denim-500 text-base transition-all duration-200",
                inputGroupIconClass,
                isDisabled ? "!bg-opplio-silver" : "",
                focused ? "border-opplio-graySky" : "border-opplio-border"
              )}
            >
              <i className={classNames("fa-fw", inputGroupIcon)}></i>
            </div>
          )}
          <div
            className={classNames(
              "w-full overflow-hidden border transition-all duration-200 bg-slate-50",
              inputBodyClass,
              isInputGroup && inputGroupPosition === "left"
                ? "!border-l-0 !rounded-l-none"
                : "",
              isInputGroup && inputGroupPosition === "right"
                ? "!border-r-0 !rounded-r-none"
                : "",
              focused ? "border-indigo-900" : "border-slate-200"
            )}
          >
            <Flatpickr
              ref={flatPicker}
              value={dateValue}
              onChange={(e) => {
                onChange({ target: { name: inputName, value: e } });
                setDateValue(e);
              }}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              className={classNames(
                "w-full h-10 bg-transparent !rounded-none !border-none py-0 px-3 text-sm font-medium text-denim-800 !ring-0 !outline-0 transition-all duration-200",
                isDisabled ? "!bg-opplio-silver" : "",
                inputClasses
              )}
              placeholder={props.placeholder}
              options={{
                altInput: dateFormat ? "true" : "false",
                altFormat: dateFormat ? dateFormat : "Y-m-d",
                enableTime: true,
                dateFormat: dateFormat ? dateFormat : "Y-m-d",
                ...datePickerProps,
                clickOpens: true,
                disabled: disabled,
              }}
            />
          </div>
          {isInputGroup && inputGroupPosition === "right" && (
            <div
              className={classNames(
                "w-10 flex-shrink-0 flex items-center justify-center border bg-white text-denim-500 text-base transition-all duration-200",
                inputGroupIconClass,
                isDisabled ? "!bg-opplio-silver" : "",
                focused ? "border-opplio-graySky" : "border-opplio-border"
              )}
            >
              <i className={classNames("fa-fw", inputGroupIcon)}></i>
            </div>
          )}
        </div>
        {errorType && errorType === "danger" && (
          <div className="text-xs font-medium text-red-600">
            <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "warning" && (
          <div className="text-xs font-medium text-amber-500">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "success" && (
          <div className="text-xs font-medium text-green-600">
            <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "info" && (
          <div className="text-xs font-medium text-sky-600">
            <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
};

export default InputDate;
