import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { apiRequest } from "../../utils/async/apiRequest";
import Button from "../form/Button";
import { toast } from "react-toastify";

const DeleteModal = ({
  isOpen = false,
  loading = false,
  customMessage = "",
  setIsOpen = () => {},
  getResponse = () => {},
  deleteFunction = () => {},
  data = {
    contentType: "application/json",
    apiEndpoint: "/",
    method: "delete",
    targetId: undefined,
    data: {},
    payload: {
      body: {
        //body or queries
        ids: undefined,
      },
    },
  },
}) => {
  const deleteHandler = async () => {
    deleteFunction();
    try {
      const res = await apiRequest(
        data?.method,
        data?.apiEndpoint + (data?.targetId ? "/" + data?.targetId : ""),
        data?.payload ? data?.payload : {},
        data?.contentType
      );
      getResponse({
        ...res,
        data: {
          responseData: res?.data,
          ...res?.data,
          ...data,
        },
      });
      return;
    } catch (error) {
      getResponse(error);
      return;
    }
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-60"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gradient-to-b from-slate-950/90 via-slate-950/40 to-slate-950/60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={`w-full max-w-sm transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all`}
                >
                  <div className="p-5">
                    <div className="text-center font-bold text-lg">
                      Delete Record
                    </div>
                    <div className="text-center mt-2 text-slate-500">
                      {customMessage ? (
                        customMessage
                      ) : (
                        <>
                          You are trying to delete the record,
                          <br /> click &quot;Delete&quot; below.
                        </>
                      )}
                    </div>
                    <div className="mt-4 flex justify-center gap-3">
                      <Button
                        buttonFunction={() => setIsOpen()}
                        buttonIcon={"fa-light fa-xmark"}
                        buttonIconPosition={"left"}
                        buttonType={"button"}
                        buttonLabel={"Cancel"}
                        buttonClasses={
                          "!py-2 !px-3 h-9 bg-indigo-900 hover:bg-indigo-500/50"
                        }
                      />
                      <Button
                        disabled={loading}
                        buttonFunction={() => {
                          if (loading) {
                            toast.info("Action in process. Please wait.");
                          } else {
                            deleteHandler();
                          }
                        }}
                        buttonIcon={
                          loading
                            ? "fa-duotone fa-spinner-third animate-spin"
                            : "fa-light fa-trash-can"
                        }
                        buttonIconPosition={"left"}
                        buttonType={"button"}
                        buttonLabel={"Delete"}
                        buttonClasses={
                          "!py-2 !px-3 h-9 bg-red-500 hover:bg-red-500/60"
                        }
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default DeleteModal;
