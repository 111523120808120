import { Menu } from "@headlessui/react";

const NotesAction = ({
  data = {},
  editAction = () => {},
  deleteAction = () => {},
  taskIndex,
  index,
}) => {
  const menu_item = [
    // {
    //   _id: 1,
    //   label: "Edit",
    //   action: () => editAction(data),
    // },
    {
      _id: 2,
      label: "Delete",
      action: () => deleteAction({ ...data, index, taskIndex }),
    },
  ];

  return (
    <Menu>
      <Menu.Button className={"w-full h-full"}>
        <i className="fa-regular fa-ellipsis-vertical text-[17px]"></i>
      </Menu.Button>
      <Menu.Items
        as="div"
        className={
          "rounded-md absolute top-1 right-0 bg-white shadow-md p-2 z-90"
        }
      >
        {menu_item &&
          menu_item?.length > 0 &&
          menu_item?.map((elm, i) => (
            <Menu.Item key={i?.toString()}>
              {({ active }) => (
                <div
                  onClick={elm?.action}
                  className="text-sm pl-4 pr-10 py-1 rounded hover:bg-indigo-50"
                >
                  {elm?.label}
                </div>
              )}
            </Menu.Item>
          ))}
      </Menu.Items>
    </Menu>
  );
};

export default NotesAction;
