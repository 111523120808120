import React, { useCallback, useEffect, useRef, useState } from "react";
import Note from "./monthlyGoalSection/Note";
import DailyTraking from "./monthlyGoalSection/DailyTraking";
import { months } from "../../constant";
import {
  addNoteAndTaskInMonth,
  getMonthList,
  progressList,
} from "../../service/monthService";
import moment from "moment";
import { toast } from "react-toastify";
import EventsNote from "./monthlyGoalSection/EventsNote";

const MonthlyGoalSection = ({
  currentYear,
  selectedMonth,
  selectedYear,
  calender = {},
}) => {
  const targetRef = useRef(null);
  const scrollDiv = useRef(null);
  const [height, setHeight] = useState(0);
  const notsRef = useRef(null);
  const [days, setdays] = useState([]);
  const [complete, setComplete] = useState({
    index: null,
    targetDay: null,
    isComplete: true,
  });
  const [content, setContent] = useState({
    loaded: false,
    data: [],
  });

  useEffect(() => {
    setHeight(notsRef?.current?.clientHeight);
  }, [notsRef]);

  function scroll() {
    if (targetRef.current && scrollDiv.current) {
      scrollDiv.current.scrollTop = targetRef.current.offsetTop - 160;
    }
  }

  const getData = useCallback(() => {
    if (selectedYear && selectedMonth) {
      getMonthList({
        type: "monthly",
        subType: "daily",
        year: selectedYear?.toString(),
        month: (selectedMonth + 1)?.toString(),
      }).then((res) => {
        if (res && res?.docs?.status == 200) {
          const updatedDaysMap = new Map();
          res?.docs?.data.forEach((updatedDay) => {
            delete updatedDay?.month;
            delete updatedDay?.year;
            delete updatedDay?.type;

            updatedDaysMap.set(updatedDay.day, {
              ...updatedDay,
              day: Number(updatedDay.day),
              notes: updatedDay.task?.map((task) => ({
                ...task,
                value: task.name,
              })),
            });
          });

          setdays((prevDays) => {
            updatedDaysMap?.forEach((item) => {
              delete item?.task;
              prevDays[Number(item?.day - 1)] = item;
            });
            return [...prevDays];
          });
          scroll();
        } else {
          scroll();
        }
      });
    }
  }, [selectedYear, selectedMonth]);

  const getProgressData = useCallback(() => {
    setContent({ loaded: false, data: [] });

    progressList({
      year: selectedYear,
      month: selectedMonth + 1,
      type: "monthly",
      subType: "daily",
    }).then((res) => {
      if (res?.status == 200) {
        setContent({ loaded: true, data: res?.data });
      } else {
        setContent({ loaded: true, data: [] });
      }
    });
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    getProgressData();
  }, [getProgressData]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className="calendar w-full space-y-5">
      <div className="flex items-center justify-center">
        <div className="text-indigo-900 font-bold text-center text-3xl capitalize">
          {months[selectedMonth]}, {selectedYear} Monthly Goals
        </div>
      </div>
      <div className="flex gap-3 w-full">
        <div className="w-full flex-grow-1">
          <div className="h-10 bg-indigo-900 text-white flex items-center justify-center mb-5 rounded">
            Daily Tracking
          </div>
          <div
            className="scrollbar overflow-y-auto min-h-[calc(100vh-100px)] scroll-smooth"
            style={{
              maxHeight: height - 65,
            }}
            ref={scrollDiv}
          >
            <DailyTraking
              targetRef={targetRef}
              monthIndex={selectedMonth}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              days={days}
              setdays={setdays}
              saveComplete={complete}
              calender={calender}
              content={content?.data}
              setContent={setContent}
            />
          </div>
        </div>
        <div
          className="flex-grow-0 h-fit w-[1000px] flex flex-col gap-y-5"
          ref={notsRef}
        >
          <Note
            title="Monthly Goal"
            content={content}
            setContent={setContent}
          />
          <EventsNote
            title="Events"
            type="event"
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            abbBtnTooltipLabel={"Add event"}
          />
          <EventsNote
            title="Birthday"
            type="birthday"
            selectedYear={selectedYear}
            selectedMonth={selectedMonth}
            abbBtnTooltipLabel={"Add birthday"}
          />
        </div>
      </div>
    </div>
  );
};

export default MonthlyGoalSection;
