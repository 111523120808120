import React from "react";
import Animation from "../animation/Animation";

const ListLoader = () => {
  return (
    <>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      {/* <div className="rounded-md h-4 w-1/3 bg-gray-200 overflow-hidden mb-1 ml-4">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-2/3 bg-gray-200 overflow-hidden mb-1">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1 opacity-[0.9]">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-2/3 bg-gray-200 overflow-hidden mb-1 opacity-[0.8] ml-6">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-1/2 bg-gray-200 overflow-hidden mb-1 opacity-[0.7] mx-auto">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1 opacity-[0.6]">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-[400px] bg-gray-200 overflow-hidden mb-1 opacity-[0.5] ml-4">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-[300px] bg-gray-200 overflow-hidden mb-1 opacity-[0.4] ml-4">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-[400px] bg-gray-200 overflow-hidden mb-1 opacity-[0.3] ml-4">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-1/2 bg-gray-200 overflow-hidden mb-1 opacity-[0.2]">
        <Animation />
      </div>
      <div className="rounded-md h-4 w-full bg-gray-200 overflow-hidden mb-1 opacity-[0.1]">
        <Animation />
      </div> */}
    </>
  );
};

export default ListLoader;
