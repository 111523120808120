import { apiRequest } from "../utils/async/apiRequest";

export const login = async (params) => {
  try {
    const res = await apiRequest("post", "user-login", {
      body: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const profile = async (params) => {
  try {
    const res = await apiRequest("get", "user/details", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const updateProfile = async (params, uploadStatus = () => {}) => {
  try {
    const res = await apiRequest(
      "put",
      "user/edit",
      {
        body: params,
      },
      "multipart/form-data",
      uploadStatus
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const changePassword = async (params) => {
  try {
    const res = await apiRequest(
      "put",
      "user/change-password",
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
