import React from "react";
import Animation from "../animation/Animation";

const NotesLoader = () => {
  return (
    <>
      <div className="rounded-[2px] h-5 w-full bg-gray-200 overflow-hidden">
        <Animation />
      </div>
      <div className="rounded-[2px] h-5 w-full bg-gray-200 overflow-hidden">
        <Animation />
      </div>
      <div className="rounded-[2px] h-5 w-full bg-gray-200 overflow-hidden">
        <Animation />
      </div>
    </>
  );
};

export default NotesLoader;
