import React from "react";
import PlanItem from "../components/sections/plans/PlanItem";
import { setTitle } from "../helpers/MetaTag";

const Plans = () => {
  setTitle("Journal 365 | Plans");
  return (
    <div className="w-2/3 mx-auto my-10">
      <div className="max-w-3xl w-full mx-auto text-center mb-10 lg:mb-20 space-y-5">
        <h2 className="capitalize text-slate-950 text-2xl xs:text-3xl !leading-tight lg:text-4xl xl:text-5xl  font-extrabold font-PlusJakartaSans">
          Pricing &amp; Plans
        </h2>
        <div className="text-sm xs:text-base text-slate-700">
          Plans are priced to fit the size of your business.
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <PlanItem />
        <PlanItem />
        <PlanItem />
      </div>
    </div>
  );
};

export default Plans;
