import React, { useEffect, useState } from "react";
import Button from "../components/form/Button";
import UserDropdown from "./UserDropdown";
import TopMenuBlock from "../components/navigation/TopMenuBlock";
import { NavLink } from "react-router-dom";
import ProfileModal from "../components/modal/ProfileModal";
import Modal from "../components/common/Modal";

const DefaultHeader = ({ isMenuOpen, setIsMenuOpen }) => {
  const [profileModal, setProfileModal] = useState({
    isOpen: false,
    data: null,
  });
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const topMenu = [
    {
      _id: "1",
      menuData: [
        {
          _id: "1-1",
          menuLabel: "Daily Highlights",
          menuLink: "/dailyhighlights",
        },
        { _id: "1-2", menuLabel: "My Month", menuLink: "/mymonth" },
        { _id: "1-3", menuLabel: "Diary", menuLink: "/diary" },
        {
          _id: "1-4",
          menuLabel: "Go PRO",
          menuLink: "/plans",
          highlight: true,
        },
      ],
    },
  ];

  const getProfileData = () => {
    setProfileModal({ isOpen: true, data: null });
  };

  return (
    <>
      <header className="sticky top-0 h-16 min-h-[64px] z-50 w-full flex items-center px-16 bg-indigo-900 shadow gap-4">
        <NavLink to={"/dailyhighlights"}>
          <div className="w-40">
            <img
              src={require("../assets/images/logo/JOURNAL-WHITE.png")}
              alt="logo"
              className="w-full h-full object-contain object-center"
            />
          </div>
        </NavLink>
        {/* <div className="relative">
          <Button buttonType={'button'} buttonIcon={'fa-solid fa-bars'} buttonIconPosition={'left'} buttonClasses={'px-0 w-10 !bg-slate-200 !text-slate-600 hover:!bg-slate-300 hover:!text-slate-900'} functions={menuToggle}/>
        </div> */}
        <div
          className={
            "relative ml-auto" + (isMenuOpen ? "divide-y divide-slate-800" : "")
          }
        >
          {topMenu.map((item) => (
            <TopMenuBlock
              key={item._id}
              menuTitle={item.menuTitle}
              menuData={item.menuData}
              isMenuOpen={isMenuOpen}
            />
          ))}
        </div>
        <div className="relative  flex justify-end items-center gap-3">
          <div className="relative">
            <UserDropdown
              xPlacement={"right"}
              showProfile={() => getProfileData()}
            />
          </div>
        </div>
      </header>
      <Modal
        size="xl4"
        isOpen={profileModal?.isOpen}
        setIsOpen={() => setProfileModal({ isOpen: false, data: null })}
      >
        <ProfileModal
          close={() => setProfileModal({ isOpen: false, data: null })}
          data={profileModal?.data}
        />
      </Modal>
    </>
  );
};

export default DefaultHeader;
