import React from "react";

const Dot = () => {
  return (
    <div className="load-icon w-full h-full flex justify-center items-center gap-1 bg-black/10 backdrop-blur-[1px] rounded-[3px]">
      <span className="w-2 h-2 flex bg-indigo-900"></span>
      <span className="w-2 h-2 flex bg-indigo-900"></span>
      <span className="w-2 h-2 flex bg-indigo-900"></span>
    </div>
  );
};

export default Dot;
