import React, { Fragment, useState, useRef, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";
import nodata from "../../assets/images/bg/NoData.svg";

const Select = ({
  isDisabled = false,
  allowRemove = false,
  xPlacement,
  dropdownData = [],
  selectName,
  value,
  placeholder = "Select",
  dropdownClass,
  dropdownButtonClass,
  label = "",
  className = "",
  labelClasses,
  errorType,
  errorText,
  loading = false,
  onChange = () => {},
  ...props
}) => {
  const [selected, setSelected] = useState(null);
  const [toggle, setToggle] = useState(false);

  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useEffect(() => {
    if (value) {
      const index = dropdownData.findIndex((item) => item?.value === value);
      if (index !== -1) {
        setSelected(dropdownData[index]);
      }
    }
  }, [value]);

  return (
    <>
      {label && (
        <div
          className={classNames(
            "text-sm font-medium text-slate-500 mb-1",
            labelClasses
          )}
        >
          {label}
        </div>
      )}
      <Listbox
        value={selected}
        onChange={(e) => {
          onChange({ target: { name: selectName, value: e?.value } });
          setSelected(e);
        }}
        as={"div"}
        className={`relative select ${className}`}
      >
        {({ open }) => {
          if (!isDisabled) {
            if (open) {
              setToggle(true);
            } else {
              setToggle(false);
            }
          }
          return (
            <>
              <Listbox.Button
                ref={setTargetElement}
                className="flex items-center w-full"
              >
                <div
                  className={classNames(
                    "relative w-full h-10 flex items-center justify-between focus:ring-0 transition-all duration-200 gap-2 pr-2 pl-3 bg-slate-50 border border-slate-200",
                    dropdownButtonClass,
                    toggle ? "!border-indigo-500" : ""
                  )}
                >
                  <div className="text-sm text-slate-500 font-medium whitespace-nowrap truncate flex-grow">
                    {selected && selected?.label && selected?.value ? (
                      <div className="text-start">{selected?.label}</div>
                    ) : (
                      <div className="text-start">{placeholder}</div>
                    )}
                  </div>
                  <div className="flex items-center justify-center flex-grow-0 text-indigo-500 text-xs w-6 h-6">
                    {!loading && !isDisabled && (
                      <>
                        {allowRemove ? (
                          !selected?.value ? (
                            <i
                              className={`fa-regular fa-chevron-${
                                open ? "up" : "down"
                              } transition-all`}
                            ></i>
                          ) : (
                            <button
                              type="button"
                              onClick={() => {
                                setToggle(false);
                                onChange({
                                  target: { name: selectName, value: "" },
                                });
                                setSelected(null);
                              }}
                            >
                              <i
                                className={`fa-solid fa-circle-xmark transition-all`}
                              ></i>
                            </button>
                          )
                        ) : (
                          <i
                            className={`fa-regular fa-chevron-${
                              open ? "up" : "down"
                            } transition-all`}
                          ></i>
                        )}
                      </>
                    )}
                    {loading && (
                      <i className="fa-duotone fa-spinner-third animate-spin"></i>
                    )}
                  </div>
                </div>
              </Listbox.Button>
              {!isDisabled && (
                <Transition
                  leave="transition duration-75"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  className="w-full z-50 absolute"
                  beforeEnter={() => setPopperElement(popperElRef.current)}
                  afterLeave={() => setPopperElement(null)}
                  ref={popperElRef}
                  style={styles.popper}
                  {...attributes.popper}
                >
                  <Listbox.Options
                    className={classNames(
                      "absolute left-0 top-0 max-w-60 w-full origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0 overflow-auto scroll-smooth scrollbar",
                      dropdownClass
                    )}
                  >
                    <div className="py-1 max-h-80 divide-y divide-slate-100 ">
                      {dropdownData &&
                        Array.isArray(dropdownData) &&
                        dropdownData?.length > 0 &&
                        dropdownData.map((item, index) => (
                          <Listbox.Option
                            key={index}
                            className={({ active }) =>
                              classNames(
                                "relative select-none py-2 px-2 text-xs leading-tight cursor-pointer transition-all duration-200 flex items-center space-x-1",
                                active
                                  ? "bg-carnation-50 text-slate-700"
                                  : "text-slate-500"
                              )
                            }
                            value={item}
                          >
                            <div className="flex items-center  text-slate-500 text-base">
                              <i
                                className={classNames("fa-fw", item?.icon)}
                              ></i>
                            </div>
                            <div
                              className={classNames(
                                "block truncate text-sm",
                                selected ? "font-medium" : "font-normal"
                              )}
                            >
                              <div
                                className={`${
                                  selected?.value === item?.value
                                    ? "text-indigo-500"
                                    : ""
                                }`}
                              >
                                {item?.label}
                              </div>
                            </div>
                          </Listbox.Option>
                        ))}
                      {dropdownData &&
                        Array.isArray(dropdownData) &&
                        dropdownData?.length == 0 && (
                          <div className="w-full h-28 flex flex-col items-center justify-center">
                            <img src={nodata} alt="no-data" />
                            <div className="text-center text-slate-400 text-sm">
                              No Data
                            </div>
                          </div>
                        )}
                    </div>
                  </Listbox.Options>
                </Transition>
              )}
            </>
          );
        }}
      </Listbox>
      {errorType && errorType === "error" && (
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="text-xs text-amber-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
          {errorText}
        </div>
      )}
      {errorType && errorType === "info" && (
        <div className="text-xs text-sky-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
          {errorText}
        </div>
      )}
    </>
  );
};

export default Select;
