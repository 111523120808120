import { apiRequest } from "../utils/async/apiRequest";

export const getDiaryList = async (params) => {
  try {
    const res = await apiRequest("get", "task/detail", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addDiary = async (params, uploadPer = () => {}) => {
  try {
    const res = await apiRequest(
      "post",
      "task/add",
      {
        body: params,
      },
      "multipart/form-data",
      (count) => {
        uploadPer(count);
      }
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
