import { Tab } from "@headlessui/react";
import React, { useEffect, useState } from "react";

const VerticalTab = ({ tabData = [] }) => {
  const [selected, setSelected] = useState(tabData[0] || {});
  const [selectIndex, setSelectIndex] = useState(0);

  useEffect(() => {
    setSelected(tabData[0]);
    setSelectIndex(0);
  }, [tabData]);

  return (
    <>
      <Tab.Group
        onChange={(index) => {
          setSelected(tabData[index]);
          setSelectIndex(index);
        }}
      >
        <div className="flex gap-2">
          <div className="flex-grow-0 w-40">
            <Tab.List as="div" className={"flex flex-col gap-y-[2px]"}>
              {tabData &&
                Array.isArray(tabData) &&
                tabData?.map((tabItem, i) => (
                  <Tab
                    key={i?.toString()}
                    className={`flex gap-2 items-center px-2 py-2 rounded-[3px] w-full text-sm hover:bg-indigo-500 hover:text-white transition-all ${
                      selectIndex == i ? "bg-indigo-500 text-white" : ""
                    }`}
                  >
                    {tabItem?.icon && (
                      <span className="h-5 w-5 flex items-center justify-center">
                        <i className={`fa-fw ${tabItem?.icon}`}></i>
                      </span>
                    )}
                    <span className="whitespace-nowrap">{tabItem?.label}</span>
                  </Tab>
                ))}
            </Tab.List>
          </div>
          <div className="flex-grow border-l border-indigo-500 border-dashed pl-5">
            {selected?.component}
          </div>
        </div>
      </Tab.Group>
    </>
  );
};

export default VerticalTab;
