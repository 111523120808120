import React, { useEffect, useRef } from "react";
import { classNames } from "../../helpers/classNames";

const Textarea = ({
  isDisabled = false,
  label,
  labelClasses,
  inputClasses,
  inputPlaceholder,
  inputValue,
  value,
  inputName,
  errorType,
  onBlur,
  onFocus,
  onChange = () => {},
  className = "",
  autoFocus = false,
  ...props
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (autoFocus && textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }
  }, [autoFocus, textareaRef]);

  return (
    <>
      {label && (
        <div
          className={"text-sm font-medium text-indigo-900 mb-1 " + labelClasses}
        >
          {label}
        </div>
      )}
      <div className={classNames("relative flex w-full", className)}>
        {isDisabled ? (
          <textarea
            ref={textareaRef}
            className={
              "w-full h-40 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-indigo-900 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200 resize-none overflow-auto scroll-smooth scrollbar " +
              inputClasses
            }
            name={inputName}
            value={value}
            defaultValue={inputValue}
            disabled={isDisabled}
          ></textarea>
        ) : (
          <textarea
            ref={textareaRef}
            className={
              "w-full h-40 rounded bg-slate-50 border border-slate-200 px-3 text-sm text-indigo-900 !ring-0 !outline-0 focus:border-gray-400 transition-all duration-200 resize-none overflow-auto scroll-smooth scrollbar " +
              inputClasses
            }
            placeholder={inputPlaceholder}
            name={inputName}
            value={value}
            defaultValue={inputValue}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          ></textarea>
        )}
      </div>
      {errorType && errorType === "error" && (
        <div className="text-xs text-red-600 mt-1">
          <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
          This field is required
        </div>
      )}
      {errorType && errorType === "warning" && (
        <div className="text-xs text-indigo-600 mt-1">
          <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
          This field has a warning
        </div>
      )}
      {errorType && errorType === "success" && (
        <div className="text-xs text-green-600 mt-1">
          <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
          Verified
        </div>
      )}
    </>
  );
};

export default Textarea;
