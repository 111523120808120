import React, { useEffect, useRef, useState } from "react";
import Nofile from "./Nofile";
import Preview from "./Preview";
import { toast } from "react-toastify";
import Modal from "../../../components/common/Modal";
import Button from "../Button";

const DraggableFileupload = ({
  max,
  multiple = false,
  fancyBox = false,
  onChange = () => {},
  onRemove = () => {},
  values,
  accept, //if multiple = false; the accept = "image/png" || accept = ["image/png"]
  uploadPercentage = 0,
}) => {
  const imgRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState(null);
  const [errorText, setErrortext] = useState("");
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    data: null,
  });

  useEffect(() => {
    if (values || values?.length > 0) {
      setFiles(values);
    }
  }, [values]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDrop = (e) => {
    if (uploadPercentage > 0) {
      toast.info("Please wait previous action not end.");
      return;
    }
    setErrortext("");
    e.preventDefault();
    if (!multiple) {
      setDragging(false);
      const droppedFiles = e.dataTransfer.files[0];
      if (accept) {
        if (accept === droppedFiles?.type) {
          onChange(droppedFiles);
          setFiles(droppedFiles);
          return;
        } else {
          if (droppedFiles) {
            setErrortext(
              `This type file is not acceptable, Only accept ${
                accept?.split("/")[1]
              } file`
            );
          } else {
            setErrortext("");
          }
          return;
        }
      } else {
        onChange(droppedFiles);
        setFiles(droppedFiles);
      }
    } else {
      setDragging(false);
      const droppedFiles = Array.from(e.dataTransfer.files);

      if (accept && Array.isArray(accept) && accept?.length > 0) {
        let dropped_files = [];
        let isError = false;
        let error = [];
        droppedFiles?.forEach((item) => {
          if (accept.includes(item?.type)) {
            dropped_files.push(item);
            return;
          } else {
            isError = true;
          }
        });
        if (isError) {
          accept?.forEach((item) => {
            error.push(item?.split("/")[1]);
          });

          if (droppedFiles && droppedFiles?.length > 0) {
            setErrortext(
              `These type files are not acceptable, Only accept ${error.join(
                ", "
              )} files`
            );
          } else {
            setErrortext("");
          }
          isError = false;
        }
        function onChangeHandler() {
          onChange(
            max
              ? values
                ? dropped_files.concat(values).slice(0, max)
                : dropped_files.slice(0, max)
              : values
              ? dropped_files?.concat(values)
              : dropped_files
          );
          setFiles(
            max
              ? values
                ? dropped_files.concat(values).slice(0, max)
                : dropped_files.slice(0, max)
              : values
              ? dropped_files?.concat(values)
              : dropped_files
          );
        }
        if (values && values?.length < 1) {
          onChangeHandler();
        } else {
          setConfirmModal({
            isOpen: true,
            data: max
              ? values
                ? dropped_files.concat(values).slice(0, max)
                : dropped_files.slice(0, max)
              : values
              ? dropped_files?.concat(values)
              : dropped_files,
          });
        }
      } else {
        function onChangeHandler() {
          onChange(
            max
              ? values
                ? droppedFiles.concat(values).slice(0, max)
                : droppedFiles.slice(0, max)
              : values
              ? droppedFiles?.concat(values)
              : droppedFiles
          );
          setFiles(
            max
              ? values
                ? droppedFiles.concat(values).slice(0, max)
                : droppedFiles.slice(0, max)
              : values
              ? droppedFiles?.concat(values)
              : droppedFiles
          );
        }
        if (values && values?.length < 1) {
          onChangeHandler();
        } else {
          setConfirmModal({
            isOpen: true,
            data: max
              ? values
                ? droppedFiles.concat(values).slice(0, max)
                : droppedFiles.slice(0, max)
              : values
              ? droppedFiles?.concat(values)
              : droppedFiles,
          });
        }
      }
    }
    imgRef.current.value = null;
  };

  const handleFileInputChange = (e) => {
    if (uploadPercentage > 0) {
      toast.info("Please wait previous action not end.");
      return;
    }
    setErrortext("");
    if (!multiple) {
      const selectedFiles = e.target.files[0];
      if (accept) {
        if (accept === selectedFiles?.type) {
          onChange(selectedFiles);
          setFiles(selectedFiles);
          return;
        } else {
          if (selectedFiles) {
            setErrortext(
              `This type file is not acceptable, Only accept ${
                accept?.split("/")[1]
              } file`
            );
          } else {
            setErrortext("");
          }
          return;
        }
      } else {
        onChange(selectedFiles);
        setFiles(selectedFiles);
        return;
      }
    } else {
      const selectedFiles = Array.from(e.target.files);
      if (accept && Array.isArray(accept) && accept?.length > 0) {
        let select_files = [];
        let isError = false;
        let error = [];
        selectedFiles?.forEach((item) => {
          if (accept.includes(item?.type)) {
            select_files.push(item);
            return;
          } else {
            isError = true;
          }
        });
        if (isError) {
          accept?.forEach((item) => {
            error.push(item?.split("/")[1]);
          });
          if (selectedFiles && selectedFiles?.length > 0) {
            setErrortext(
              `These type files are not acceptable, Only accept ${error.join(
                ", "
              )} files`
            );
          } else {
            setErrortext("");
          }
          isError = false;
        }
        function onChangeHandler() {
          onChange(
            max
              ? values
                ? select_files.concat(values).slice(0, max)
                : select_files.slice(0, max)
              : values
              ? select_files?.concat(values)
              : select_files
          );
          setFiles(
            max
              ? values
                ? select_files.concat(values).slice(0, max)
                : select_files.slice(0, max)
              : values
              ? select_files?.concat(values)
              : select_files
          );
        }
        if (values && values?.length < 1) {
          onChangeHandler();
        } else {
          setConfirmModal({
            isOpen: true,
            data: max
              ? values
                ? select_files.concat(values).slice(0, max)
                : select_files.slice(0, max)
              : values
              ? select_files?.concat(values)
              : select_files,
          });
        }
      } else {
        function onChangeHandler() {
          onChange(
            max
              ? values
                ? selectedFiles.concat(values).slice(0, max)
                : selectedFiles.slice(0, max)
              : values
              ? selectedFiles?.concat(values)
              : selectedFiles
          );
          setFiles(
            max
              ? values
                ? selectedFiles.concat(values).slice(0, max)
                : selectedFiles.slice(0, max)
              : values
              ? selectedFiles?.concat(values)
              : selectedFiles
          );
        }
        if (values && values?.length < 1) {
          onChangeHandler();
        } else {
          setConfirmModal({
            isOpen: true,
            data: max
              ? values
                ? selectedFiles.concat(values).slice(0, max)
                : selectedFiles.slice(0, max)
              : values
              ? selectedFiles?.concat(values)
              : selectedFiles,
          });
        }
        // onChange(
        //   max
        //     ? values
        //       ? selectedFiles.concat(values).slice(0, max)
        //       : selectedFiles.slice(0, max)
        //     : values
        //     ? selectedFiles?.concat(values)
        //     : selectedFiles
        // );
        // setFiles(
        //   max
        //     ? values
        //       ? selectedFiles.concat(values).slice(0, max)
        //       : selectedFiles.slice(0, max)
        //     : values
        //     ? selectedFiles?.concat(values)
        //     : selectedFiles
        // );
      }
    }
    imgRef.current.value = null;
  };

  function removeFile({ data, index, multiple }) {
    if (multiple) {
      setFiles(files?.filter((_, i) => i !== index));
      onChange(files?.filter((_, i) => i !== index));
      onRemove(files?.filter((_, i) => i !== index));
    } else {
      setFiles(null);
      onChange(null);
      onRemove(null);
    }
  }

  const confirmImageUploadFun = () => {
    const data = confirmModal?.data;
    onChange(data);
    setFiles(data);
    setConfirmModal({ isOpen: false, data: null });
  };

  return (
    <>
      <div className="flex gap-2 items-center">
        <div
          className={`dropzone relative w-32 h-32 overflow-hidden ${
            dragging ? "dragging" : ""
          } rounded-md border-2 border-slate-400 border-dashed`}
          onDragOver={handleDragOver}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <input
            ref={imgRef}
            type="file"
            id="fileInput"
            onChange={handleFileInputChange}
            multiple={multiple}
            style={{ display: "none" }}
          />
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <Nofile error={errorText} />
          </div>
          <label
            htmlFor="fileInput"
            className="absolute top-0 left-0 opacity-0 w-full h-full flex"
          ></label>
        </div>
        <Preview
          data={files}
          multiple={multiple}
          removeFile={removeFile}
          uploadPercentage={uploadPercentage}
          fancyBox={fancyBox}
        />
      </div>
      <Modal isOpen={confirmModal?.isOpen} size="md">
        <div className="p-5">
          <div className="text-center font-bold text-lg">Alert</div>
          <div className="text-center mt-2 text-slate-500">
            You have reached the image upload limit.
            <br /> If you upload a new image, it will replace the previous one.
          </div>
          <div className="mt-4 flex justify-center gap-3">
            <Button
              buttonFunction={() =>
                setConfirmModal({ isOpen: false, data: null })
              }
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-close"}
              buttonType={"button"}
              buttonLabel={"Cancel"}
              buttonClasses={
                "!py-2 !px-3 h-9 bg-indigo-900 hover:bg-indigo-500/50"
              }
            />
            <Button
              buttonFunction={confirmImageUploadFun}
              buttonType={"button"}
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-rotate"}
              buttonLabel={"Yes Replace"}
              buttonClasses={"!py-2 !px-3 h-9 bg-red-500 hover:bg-red-500/60"}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DraggableFileupload;
