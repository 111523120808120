import React from "react";

const DailyTrackItem = ({
  type = "quantifiable",
  time = 30,
  title = "",
  remark = "",
}) => {
  return (
    <div className="rounded p-3 shadow-sm hover:shadow-lg transition-all bg-white cursor-pointer">
      <div className="flex gap-3 items-start justify-between">
        {title && (
          <div className="text-sm bg-indigo-900 w-fit p-1 px-4 text-white rounded">
            {title}
          </div>
        )}
        <div className="flex gap-1 ml-auto">
          <div className="bg-indigo-300 p-1 px-4 rounded flex items-center gap-1 text-indigo-900 text-xs">
            <span className="flex items-center justify-center w-3 h-3">
              <i className="fa-regular fa-clock"></i>
            </span>
            <span className="font-semibold">{time}</span>
          </div>
          <div className="bg-indigo-300 p-1 px-4 rounded flex items-center gap-1 text-indigo-900 text-xs">
            <span
              className={`flex w-2 h-2 bg-${
                type == "quantifiable" ? "green" : "red"
              }-500 rounded`}
            ></span>
            <span className="capitalize">{type}</span>
          </div>
        </div>
      </div>
      {remark && <div className="mt-3 text-indigo-900">{remark}</div>}
    </div>
  );
};

export default DailyTrackItem;
