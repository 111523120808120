import React, { useEffect, useState } from "react";
import Select from "../../form/Select";
import Input from "../../form/Input";
import Button from "../../form/Button";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import {
  addNoteAndTaskInMonth,
  listByGoalType,
} from "../../../service/monthService";
import moment from "moment";
import { toast } from "react-toastify";

const validation = {
  identicalId: { required: true, message: "Goal is required" },
  name: { required: true, message: "Remark is required" },
  completedTime: { required: true, message: "Total time is required" },
};

const AddNoteModal = ({ cancel = () => {}, data, setResData = () => {} }) => {
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const [quantifiable, setQuantifiable] = useState({
    loaded: true,
    data: [],
  });
  const [loading, setLoading] = useState({
    submitComplete: true,
  });

  useEffect(() => {
    setQuantifiable({ loaded: false, data: [] });
    listByGoalType({
      year: data?.year,
      month: data?.month,
      type: "daily",
      subType: "daily",
      goalType: "quantifiable",
    }).then((res) => {
      if (res?.status === 200) {
        setQuantifiable({ loaded: true, data: res?.data });
      } else {
        setQuantifiable({ loaded: true, data: [] });
      }
    });
  }, []);

  const onSubmit = (value) => {
    const form_data = {
      type: "monthly",
      subType: "daily",
      date: moment(new Date(data?.year, data?.month - 1, data?.day)).format(
        "YYYY-MM-DD"
      ),
      ...value,
    };
    setLoading((pre) => ({ ...pre, submitComplete: false }));
    addNoteAndTaskInMonth(form_data).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        setResData({ data: res?.data, monthlyProgress: res?.monthlyProgress });
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
      setLoading((pre) => ({ ...pre, submitComplete: true }));
      cancel();
    });
  };

  return (
    <>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="flex flex-col gap-3">
          <div>
            <Select
              label="Select Goal"
              placeholder="Select goal"
              dropdownButtonClass="!rounded"
              selectName={"identicalId"}
              value={values?.identicalId}
              onChange={handleChange}
              loading={!quantifiable?.loaded}
              dropdownData={
                quantifiable?.data &&
                Array.isArray(quantifiable?.data) &&
                quantifiable?.data?.map((item) => ({
                  label: item?.name,
                  value: item?.identicalId,
                }))
              }
              {...formValidate(errors, "identicalId")}
            />
          </div>
          <div>
            <Input
              label="Target complete"
              inputName={"completedTime"}
              value={values?.completedTime}
              inputType={"number"}
              inputClasses={"!rounded"}
              inputPlaceholder={"Set complete target"}
              onChange={handleChange}
              {...formValidate(errors, "completedTime")}
            />
          </div>
          <div>
            <Input
              label="Remark"
              inputName={"name"}
              value={values?.name}
              inputClasses={"!rounded"}
              inputPlaceholder={"Enter remark"}
              onChange={handleChange}
              {...formValidate(errors, "name")}
            />
          </div>
          <div className="flex justify-end mt-3 gap-2">
            <Button
              buttonIconPosition={"left"}
              buttonIcon={"fa-regular fa-close"}
              buttonLabel={"Cancel"}
              buttonFunction={cancel}
            />
            <Button
              disabled={!loading?.submitComplete}
              buttonType="submit"
              buttonIconPosition={"left"}
              buttonIcon={`${
                loading?.submitComplete
                  ? "fa-regular fa-save"
                  : "fa-duotone fa-spinner-third animate-spin transition-all"
              }`}
              buttonLabel={"Save Note"}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default AddNoteModal;
