import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const Button = ({
  buttonClasses,
  buttonType = "button",
  buttonIcon,
  buttonIconPosition,
  buttonLabel,
  buttonLabelClasses,
  buttonFunction = () => {},
  buttonHasLink,
  buttonLink,
  functions,
  disabled = false,
  tooltip = { show: false, label: "", id: "btn-tooltip" },
  ...props
}) => {
  return (
    <>
      {buttonHasLink ? (
        <Link
          to={buttonLink}
          className={
            "flex justify-center items-center gap-2 bg-indigo-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 " +
            buttonClasses
          }
        >
          {buttonIconPosition === "left" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
          {buttonLabel && (
            <span className={"text-sm font-medium " + buttonLabelClasses}>
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
        </Link>
      ) : (
        <button
          data-tooltip-id={tooltip?.id || "btn-tooltip"}
          data-tooltip-content={tooltip?.label}
          type={buttonType}
          disabled={disabled}
          className={
            "flex justify-center items-center gap-2 bg-indigo-500 text-white rounded text-lg h-10 py-0 px-3 transition-all duration-200 " +
            buttonClasses
          }
          onClick={buttonFunction}
        >
          {buttonIconPosition === "left" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
          {buttonLabel && (
            <span className={"text-sm font-medium " + buttonLabelClasses}>
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i className={"fa-fw " + buttonIcon}></i>
          )}
        </button>
      )}
      {tooltip?.show && (
        <Tooltip
          id={tooltip?.id || "btn-tooltip"}
          place="bottom"
          className="!text-xs max-w-[300px] p-2"
        />
      )}
    </>
  );
};

export default Button;
